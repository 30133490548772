<template>
 <div>
            <div class="bg-gray-200 p-[1.5px] rounded-full">

                    <img class="p-1 bg-white rounded-full " :src="img" alt="" />

            </div>
            <p class="w-20 text-xs text-center truncate">{{username}}</p>
        </div>
</template>

<script>
export default {
    name: "ProfileStory",
    props: ["img", "username"],
}
</script>

<style>

</style>