import axios from "axios"

const state = {
    appconfig: {
        language: 'de',
    }
}

const getters = {
    appconfig: state => {
        return state.appconfig
    },
}

const mutations = {
    setAppConfig(state, appconfig) {
        console.log("SETTING APPCONFIG");
        console.log(appconfig);
        state.appconfig = appconfig;
    }
}

const actions = {
    fetchConfig({
        commit, state
    }, that) {
        console.log("Loading language");
        axios.get('/config').then(res => {
            console.log("CONFIG LOADED");
            console.log(res.data);
            that.$i18n.locale = res.data.language;
            console.log("DISPATCHING");
            commit('setAppConfig', res.data);
        }).catch(error => {
            console.log('Unable to fetch config');
        })
    }
}

export default {
     state, getters, mutations, actions
}