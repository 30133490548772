var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p-4 bg-white rounded shadow " }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "w-full max-w-xs mt-32 mb-12",
      },
      [_vm._m(0)]
    ),
    _vm._v(" "),
    !_vm.loading
      ? _c("div", [
          _c("div", { staticClass: "flex items-center justify-between" }, [
            _c("div", [
              _vm.authUser
                ? _c("div", { staticClass: "w-8" }, [
                    _c("img", {
                      staticClass: "object-cover w-8 h-8 rounded-full",
                      attrs: { src: _vm.authUser.avatarUri },
                    }),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 mx-4" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.postMessage,
                    expression: "postMessage",
                  },
                ],
                staticClass: "w-full h-8 pl-4 bg-gray-200 rounded-full",
                attrs: {
                  type: "text",
                  name: "body",
                  placeholder: _vm.$t("message.addagroup"),
                },
                domProps: { value: _vm.postMessage },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.postMessage = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm.postMessage
                ? _c(
                    "button",
                    {
                      staticClass: "px-2 py-1 ml-2 bg-gray-200 rounded-full",
                      on: {
                        click: function ($event) {
                          return _vm.dispatchPostMessage()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.docreate")))]
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "m-4" },
            [
              _c("vue-dropzone", {
                ref: "myVueDropzone",
                attrs: {
                  awss3: _vm.awss3,
                  id: "dropzone",
                  options: _vm.dropzoneOptions,
                },
                on: {
                  "vdropzone-upload-progress": _vm.sending,
                  "vdropzone-s3-upload-error": _vm.s3UploadError,
                  "vdropzone-s3-upload-success": _vm.s3UploadSuccess,
                  "vdropzone-success": _vm.added,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("message.totalkb")) +
                ": " +
                _vm._s(_vm.totalKB) +
                " Kilobytes\n                "
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex items-center justify-center " }, [
      _c("div", {
        staticClass:
          "w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }