import { data } from "autoprefixer"
import axios from "axios"

const state = {
    commentsList : [],
    totalComments: 0
}

const getters = {
  commentsList : state => {
      console.log("GETTINGG "+state.commentsList);
      return state.commentsList
  },
  totalComments: state => {
    return state.totalComments
  }
}


const actions = {
  fetchComments({ commit, state }, data) {
    commit('setPostsStatus', 'loading'); // data.page must get added to following axios request
    console.error("GETING POSTCOMMENTS");
    axios.get('/api/postcomments/'+ data.postid+'/'+data.page).then(resp => {
      console.log("GOT COMMENTS");
      console.error("GOT  POSTCOMMENTS");
      console.log(resp.data);
      commit('setCommentsList', resp.data);
  }).catch(error => {
    console.log("Error loading users");
});
},
getTotalComments({commit, state}, data) {
  axios.get('/api/totalcomments/'+ data.postid).then(resp => {
    console.log("GOT TOTALCOMMENTS");
    console.log(resp.data);
    console.log(resp.data.totalComments);
    commit('updateTotalComments', resp.data.totalComments);
    }).catch(error => {
    });
}

}

  const mutations = {
    setCommentsList(state, comments) {
      state.commentsList = comments;
    },
    updateTotalComments(state, total) {
      state.totalComments = total;
    },
  }

  export default {
    state, getters, actions, mutations
  }