<template>
<div class="grid h-screen place-items-center">
  <div class="w-full max-w-xs">
  <form class="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
      <p>Reset password</p>
    <p>{{error}}</p>
    <div class="mb-4">
      <label class="block mb-2 text-sm font-bold text-gray-700" for="username">
       {{ $t('message.emailaddress') }}
      </label>
      <input @input="resetError" v-model="form.username_email" class="w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Email">
      <p class="text-red-500">{{this.errors.username_email}}</p>
    </div>
    <div class="flex items-center justify-between">
      <button @click="checkForm" class="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline" type="button">
        {{ $t('message.resetpassword') }}
      </button>
      <router-link to="/login" class="px-6">
      <a class="inline-block text-sm font-bold text-blue-500 align-baseline hover:text-blue-800" href="#">
        {{ $t('message.login') }}?
      </a>
      </router-link>
    </div>
  </form>
</div>
</div>
</template>

<script>
import axios from "axios";
export default {
    name: 'Login',
     data(){
        return{
            error: '',
            errors: {
                username_email: '',
            },
            form: {
                username: '',
            }
        };
     },
     methods: {
       resetError: function() {
         this.error='';
       },
        checkForm: function (e) {
            console.log("Called checkform");

            let error = false;


            console.log("ERROR::"+error);

            if (!this.form.username_email) {
                this.errors.username_email=this.$t('message.emailrequired');
                error= true;
            }
            else {
                this.errors.username_email='';
            }

            if (error == true) {
                console.log("Preventing request");
            e.preventDefault();
            } else {
                console.log("Submitting form");
                axios.post('/api/resetpassword', this.form)
                            .then(res => {
                                if (res.data.status=='ok') {
                                  alert(this.$t('message.passwordreset'));
                                    this.$router.push('/login');
                                  }
                                else if (res.data.status=='error') {
                                    this.error=this.$t('message.wrongemail');
                                  }
                            }).catch(error => {
                               alert("A network error occured during password reset");
                            })
            }
            },
        validEmail: function (email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
        }

     }

}
</script>

<style>

</style>