<template>
<div class="grid h-screen place-items-center">

  <div v-show="login" class="w-full max-w-xs">
<div class="flex items-center justify-center ">
    <div class="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
    </div>
</div>
<div v-show="!login">

  <form class="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
    <p>Login</p>
    <p>{{error}}</p>
    <div class="mb-4">
      <label class="block mb-2 text-sm font-bold text-gray-700" for="username">
       {{ $t('message.emailaddress') }}
      </label>
      <input @input="resetError" v-model="form.username_email" class="w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Email">
      <p class="text-red-500">{{this.errors.username_email}}</p>
    </div>
    <div class="mb-6">
      <label class="block mb-2 text-sm font-bold text-gray-700" for="password">
        {{ $t('message.password') }}
      </label>
      <input @input="resetError" v-model="form.password" class="w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="******************">
      <p class="text-red-500">{{this.errors.password}}</p>
    </div>
    <div class="flex items-center justify-between">
      <button @click="checkForm" class="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline" type="button">
        {{ $t('message.login') }}
      </button>
    </div>
     <p class="">
      <router-link to="/register">
      <a class="inline-block text-sm font-bold text-blue-500 align-baseline hover:text-blue-800" href="#">
        {{ $t('message.signup') }}?
      </a>
      </router-link>
      </p>
      <p>
       <router-link to="/resetpassword">
      <a class="inline-block text-sm font-bold text-blue-500 align-baseline hover:text-blue-800" href="#">
        {{ $t('message.resetpasswordquestion') }}
      </a>
      </router-link>
      </p>

  </form>
  </div>
</div>
</div>
</template>

<script>
import axios from "axios";
export default {
    name: 'Login',
     data(){
        return{
            error: '',
            errors: {
                username_email: '',
                password: '',
            },
            form: {
                username: '',
                password: '',
            },
            login : false
        };
     },
     methods: {
       resetError: function() {
         this.error='';
       },
        checkForm: function (e) {
            console.log("Called checkform");

            let error = false;


            console.log("ERROR::"+error);

            if (!this.form.username_email) {
                this.errors.username_email=this.$t('message.emailrequired');
                error= true;
            }
            else {
                this.errors.username_email='';
            }

            if (!this.form.password) {
                this.errors.password=this.$t('message.passwordrequired');
                error = true;
            } else {
                this.errors.password='';
            }
            console.log("ERROR::"+error);
            if (error == true) {
                console.log("Preventing request");
            e.preventDefault();
            } else {
                console.log("Submitting form");
                this.login = true;
                axios.post('/login', this.form)
                            .then(res => {
                              this.login = false;
                                if (res.data.status=='ok') {
                                    this.$store.dispatch('fetchAuthUser');
                                    this.$router.push('/');
                                  }
                                else if (res.data.status=='error') {
                                    this.error=this.$t('message.wrongemailorpassword');
                                  }
                            }).catch(error => {
                              this.login = false;
                               alert("A network error occured during login");
                            })
            }
            },
        validEmail: function (email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
        }

     }

}
</script>

<style>

</style>