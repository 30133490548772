<template>
 <div class="mt-6 overflow-hidden bg-white rounded shadow">
        <div class="flex flex-col p-4">
            <div class="flex items-center">
                <div class="w-8">
                    <router-link :to="'/'+grouppost.user.userName">
                    <img :src="grouppost.user.avatarUri">
                    </router-link>
                </div>
                <div class="ml-6">
                    <div>{{ grouppost.user.displayName }}</div>
                    <div>{{ grouppost.humanCreatedAt }} </div>
                </div>
            </div>
            <div class="mt-4">{{ grouppost.body }}</div>

            <div class="w-full" v-if="grouppost.photo!=null">
                <div v-if="grouppost.photoType=='mp4'">
                    <vue-plyr>
                        <video
                            controls
                            crossorigin
                            playsinline
                        >
                            <source
                            :src="grouppost.photo"
                            type="video/mp4"
                            />
                        </video>
                        </vue-plyr>
                </div>
                <div v-else>
                    <img :src="grouppost.photo" class="w-full">
                </div>
            </div>
            <div class="flex justify-between m-4 border-gray-400 border-1">
                    <button @click="likePost" class="flex justify-center w-full p-4 rounded hover:bg-gray-100 " :class="grouppost.logged_in_user_likes_post == 1 ? `bg-blue-600 text-white hover:text-black` : ``">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5 fill-current">
                        <path d="M20.8 15.6c.4-.5.6-1.1.6-1.7 0-.6-.3-1.1-.5-1.4.3-.7.4-1.7-.5-2.6-.7-.6-1.8-.9-3.4-.8-1.1.1-2 .3-2.1.3-.2 0-.4.1-.7.1 0-.3 0-.9.5-2.4.6-1.8.6-3.1-.1-4.1-.7-1-1.8-1-2.1-1-.3 0-.6.1-.8.4-.5.5-.4 1.5-.4 2-.4 1.5-2 5.1-3.3 6.1l-.1.1c-.4.4-.6.8-.8 1.2-.2-.1-.5-.2-.8-.2H3.7c-1 0-1.7.8-1.7 1.7v6.8c0 1 .8 1.7 1.7 1.7h2.5c.4 0 .7-.1 1-.3l1 .1c.2 0 2.8.4 5.6.3.5 0 1 .1 1.4.1.7 0 1.4-.1 1.9-.2 1.3-.3 2.2-.8 2.6-1.6.3-.6.3-1.2.3-1.6.8-.8 1-1.6.9-2.2.1-.3 0-.6-.1-.8zM3.7 20.7c-.3 0-.6-.3-.6-.6v-6.8c0-.3.3-.6.6-.6h2.5c.3 0 .6.3.6.6v6.8c0 .3-.3.6-.6.6H3.7zm16.1-5.6c-.2.2-.2.5-.1.7 0 0 .2.3.2.7 0 .5-.2 1-.8 1.4-.2.2-.3.4-.2.6 0 0 .2.6-.1 1.1-.3.5-.9.9-1.8 1.1-.8.2-1.8.2-3 .1h-.1c-2.7.1-5.4-.3-5.4-.3H8v-7.2c0-.2 0-.4-.1-.5.1-.3.3-.9.8-1.4 1.9-1.5 3.7-6.5 3.8-6.7v-.3c-.1-.5 0-1 .1-1.2.2 0 .8.1 1.2.6.4.6.4 1.6-.1 3-.7 2.1-.7 3.2-.2 3.7.3.2.6.3.9.2.3-.1.5-.1.7-.1h.1c1.3-.3 3.6-.5 4.4.3.7.6.2 1.4.1 1.5-.2.2-.1.5.1.7 0 0 .4.4.5 1 0 .3-.2.6-.5 1z"/>
                        </svg><p class="ml-2">Like</p>
                    </button>
                    <button @click="loadComments(grouppost.id)" class="flex justify-center w-full p-4 rounded hover:bg-gray-100"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5 fill-current"><path d="M20.3 2H3.7C2 2 .6 3.4.6 5.2v10.1c0 1.7 1.4 3.1 3.1 3.1V23l6.6-4.6h9.9c1.7 0 3.1-1.4 3.1-3.1V5.2c.1-1.8-1.3-3.2-3-3.2zm1.8 13.3c0 1-.8 1.8-1.8 1.8H9.9L5 20.4V17H3.7c-1 0-1.8-.8-1.8-1.8v-10c0-1 .8-1.8 1.8-1.8h16.5c1 0 1.8.8 1.8 1.8v10.1zM6.7 6.7h10.6V8H6.7V6.7zm0 2.9h10.6v1.3H6.7V9.6zm0 2.8h10.6v1.3H6.7v-1.3z"/></svg><p class="ml-2">{{ $t('message.tocomment') }}</p></button>
            </div>
            <div class="flex items-center my-4" v-for="(comment, commentKey) in comments" v-bind:key="commentKey">
                <div class="w-8">
                    <router-link :to="'/'+comment.postedBy.userName">
                    <img :src="comment.postedBy.avatarUri" class="w-8 h-8">
                    </router-link>
                </div>
                <div class="flex-1 ml-4">
                    <div class="text-sm bg-gray-200 rounded-lg p2">
                    <p class="text-lg"><a :href="'/'+comment.postedBy.id">{{comment.postedBy.displayName }}</a></p>
                    <p class="inline">{{comment.text}}</p>
                    </div>

                </div>
                 <div @click="deleteComment(comment.id)" v-if="admin">
                        <p>DEL</p>
                    </div>
            </div>
            <div v-if="comments" class="flex w-full">
                    <div class="w-full">
                        <input v-model="commentBody" type="text" name="comment" class="w-full h-8 pl-4 bg-gray-200 rounded">
                    </div>
                    <button v-if="commentBody.length>0" @click="postComment(grouppost.id, commentBody);" class="px-3 py-1 ml-2 bg-gray-200 rounded">
                        {{ $t('message.post') }}
                    </button>
            </div>
            <div class="flex justify-between px-4 text-sm text-gray-700">
                   <div>
                        <p v-if="grouppost.likesLength != 1">{{ grouppost.likesLength }} likes</p>
                        <p v-else>1 like</p>
                    </div>
                    <div>
                         <p v-if="grouppost.commentsLength != 1">{{ grouppost.commentsLength }}  {{ $t('message.comments') }}</p>
                        <p v-else>1  {{ $t('message.comment') }}</p>
                    </div>
                    <div @click="deletePost(grouppost.id)" v-if="admin && authUser">
                        <p>DELETE</p>
                    </div>
            </div>
        </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from 'vuex';
export default {
    name: "GroupPost",
    props: ["grouppost", "groupPostKey"],
    methods: {
        likePost() {
            console.log("ID:"+this.grouppost.id);
            console.log("KEY:"+this.groupPostKey);
            this.$emit('like', { postId: this.grouppost.id, groupPostKey: this.groupPostKey });
        },
        loadComments(id) {
            axios.post('/api/postcomments/'+id)
            .then(res => {
                this.comments = res.data;
            }).catch(error => {
                console.error("An error occured posting the comment");
            })
        },
        postComment(id, body) {
                axios.post('/api/postcomment', { 'post_id' : id, 'body': body})
                            .then(res => {
                                this.comments.unshift(res.data);
                                this.grouppost.commentsLength++;
                                this.commentBody = '';
                            }).catch(error => {
                                console.error("An error occured loading the comments");
                            });
        },
        deletePost(id) {
             if (confirm('Are you sure?')) {
                axios.post('/api/deletepost', { 'post_id' : id })
                            .then(res => {
                                console.log("Deleted post");
                                this.$store.dispatch('removeGroupDetailPost', id);
                            }).catch(error => {
                                console.error("An error occured deleting the posts");
                            });
             }
        },
         deleteComment(id) {
             if (confirm('Are you sure?')) {
                axios.post('/api/deletecomment', { 'comment_id' : id })
                            .then(res => {
                                console.log("Deleted comment");
                            }).catch(error => {
                                console.error("An error occured deleting the comment");
                            });
             }
        }
      }, data : () => {
        return {
            comments : null,
            commentBody : ''
        }
    }, computed: {
        ...mapGetters({
            admin: 'admin',
            authUser : 'authUser'
        }),

    }

}
</script>

<style>

</style>