<template>
 <div v-if="loggedOut">{{ $t('message.successfullyloggedout') }}</div>
</template>

<script>
import axios from "axios";

export default {
    name: 'Logout',
    data() {
        return {
            loggedOut : false,
        }
    },
    mounted() {
        axios.get('/logout')
                            .then(res => {
                                this.loggedOut = true;
                                 this.$store.dispatch('fetchAuthUser');
                            }).catch(error => {
                                console.error("Please clear your cookies to logout");
                            });
    }
}
</script>

<style>

</style>