<template>
 <div class="mb-4 bg-white rounded shadow w-100">

                  <div class="p-4">
                    <div class="flex flex-col">
                    <img :src="groupMember.avatarUri" class="w-8 h-8 rounded-full">{{ groupMember.firstName }} {{ groupMember.lastName }} {{ groupMember.id }}
                    </div>
                </div>
                <div class="flex" v-if="groupDetails.isOwner == 1">
                    <input @click="deleteUserFromGroup(groupMember.id)" type="button" :value="$t('message.remove')" class="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline">
                </div>
 </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from "axios";
export default {

    name: "GroupMember",
    props: ["groupMember", "page"],
     computed: {
        ...mapGetters({
            groupDetails: 'groupDetails'
        }),
    },
   methods: {
        deleteUserFromGroup(id) {
            if (confirm('Are you sure?')) {
                axios.get('/api/group/delete_member_from_group/'+id+"/"+this.$route.params.id)
                                        .then(res => {
                                            console.log("Removed membership");
                                            this.$store.dispatch('fetchGroupMembers', { page: this.page, groupid: this.$route.params.id });
                                            this.$store.dispatch('getTotalGroupMembers',  { groupid: this.$route.params.id });
                                        }).catch(error => {
                                            console.error("An error occured deleting the group member");
                                        });
            }
        }
   },


}
</script>

<style>

</style>