var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("nav", { staticClass: "flex flex-col bg-white tabs sm:flex-row" }, [
      _c(
        "button",
        {
          staticClass:
            "block px-6 py-4 font-medium text-gray-600 text-blue-500 tab hover:text-blue-500 focus:outline-none",
          class:
            _vm.currentTab == "panel-1" ? "border-b-2 border-blue-500" : "",
          attrs: { "data-target": "panel-1" },
          on: { click: _vm.onTabClick },
        },
        [
          _vm._v(
            "\n                                Group posts\n                            "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "block px-6 py-4 text-gray-600 tab hover:text-blue-500 focus:outline-none",
          class:
            _vm.currentTab == "panel-2" ? "border-b-2 border-blue-500" : "",
          attrs: { "data-target": "panel-2" },
          on: { click: _vm.onTabClick },
        },
        [
          _vm._v(
            "\n                                Group members\n                            "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.authUser && _vm.groupDetails.isOwner == 1
        ? _c(
            "button",
            {
              staticClass:
                "block px-6 py-4 text-gray-600 tab hover:text-blue-500 focus:outline-none",
              class:
                _vm.currentTab == "panel-3" ? "border-b-2 border-blue-500" : "",
              attrs: { "data-target": "panel-3" },
              on: { click: _vm.onTabClick },
            },
            [
              _vm._v(
                "\n                                Group details\n                            "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.authUser && _vm.groupDetails.isOwner == 1
        ? _c(
            "button",
            {
              staticClass:
                "block px-6 py-4 text-gray-600 tab hover:text-blue-500 focus:outline-none",
              class:
                _vm.currentTab == "panel-4" ? "border-b-2 border-blue-500" : "",
              attrs: { "data-target": "panel-4" },
              on: { click: _vm.onTabClick },
            },
            [
              _vm._v(
                "\n                                Group image\n                            "
              ),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.currentTab == "panel-1"
      ? _c("div", { staticClass: "py-5 panel-3 tab-content" }, [
          _vm.loading
            ? _c("div", { staticClass: "w-full max-w-xs" }, [_vm._m(0)])
            : _c(
                "div",
                [
                  _vm.authUser
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "p-4 mt-4 bg-white rounded shadow " },
                          [
                            _c("div", [
                              _c("p", [
                                _vm._v(_vm._s(_vm.groupDetails.groupName)),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.groupDetails.groupDescription)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(_vm._s(_vm.groupDetails.groupTags)),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.groupDetails.isRequesting == 1
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "p-4 mt-4 bg-white rounded shadow ",
                                },
                                [
                                  _c("div", [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.$t("message.youarewaiting"))
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.groupDetails.isOwner == 1
                          ? _c("div", [_c("NewOwner")], 1)
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.groupDetails.isOwner == 0 &&
                        _vm.groupDetails.isMember == 0 &&
                        _vm.groupDetails.isRequesting == 0
                          ? _c("div", [_c("NewJoinRequest")], 1)
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", [
                          _vm.groupDetails.isMember == 1 ||
                          _vm.groupDetails.isOwner == 1
                            ? _c("div", [_c("NewGroupPost")], 1)
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("vue-ads-pagination", {
                    attrs: {
                      "total-items": _vm.totalGroupPosts,
                      "max-visible-pages": 5,
                      page: _vm.page,
                      loading: _vm.totalGroupPosts == 0,
                    },
                    on: {
                      "page-change": _vm.pageChange,
                      "range-change": _vm.rangeChange,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vue-ads-pr-2 vue-ads-leading-loose",
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.$t("message.items")) +
                                      " " +
                                      _vm._s(props.start) +
                                      " " +
                                      _vm._s(_vm.$t("message.to")) +
                                      " " +
                                      _vm._s(props.end) +
                                      " " +
                                      _vm._s(_vm.$t("message.of")) +
                                      " " +
                                      _vm._s(props.total) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "buttons",
                          fn: function (props) {
                            return _vm._l(
                              props.buttons,
                              function (button, key) {
                                return _c(
                                  "vue-ads-page-button",
                                  _vm._b(
                                    {
                                      key: key,
                                      on: {
                                        "page-change": function ($event) {
                                          _vm.page = button.page
                                        },
                                      },
                                    },
                                    "vue-ads-page-button",
                                    button,
                                    false
                                  )
                                )
                              }
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      3175423325
                    ),
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.groupposts, function (grouppost, groupPostKey) {
                    return _vm.groupposts != null
                      ? _c("GroupPost", {
                          key: grouppost.id,
                          attrs: {
                            groupPostKey: groupPostKey,
                            grouppost: grouppost,
                          },
                          on: { like: _vm.userLike },
                        })
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _c("vue-ads-pagination", {
                    attrs: {
                      "total-items": _vm.totalGroupPosts,
                      "max-visible-pages": 5,
                      page: _vm.page,
                      loading: _vm.totalGroupPosts == 0,
                    },
                    on: {
                      "page-change": _vm.pageChange,
                      "range-change": _vm.rangeChange,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vue-ads-pr-2 vue-ads-leading-loose",
                                },
                                [
                                  _vm._v(
                                    "\n                   " +
                                      _vm._s(_vm.$t("message.items")) +
                                      " " +
                                      _vm._s(props.start) +
                                      " " +
                                      _vm._s(_vm.$t("message.to")) +
                                      " " +
                                      _vm._s(props.end) +
                                      " " +
                                      _vm._s(_vm.$t("message.of")) +
                                      " " +
                                      _vm._s(props.total) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "buttons",
                          fn: function (props) {
                            return _vm._l(
                              props.buttons,
                              function (button, key) {
                                return _c(
                                  "vue-ads-page-button",
                                  _vm._b(
                                    {
                                      key: key,
                                      on: {
                                        "page-change": function ($event) {
                                          _vm.page = button.page
                                        },
                                      },
                                    },
                                    "vue-ads-page-button",
                                    button,
                                    false
                                  )
                                )
                              }
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      1267739645
                    ),
                  }),
                ],
                2
              ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.currentTab == "panel-2"
      ? _c(
          "div",
          { staticClass: "py-5 panel-2 tab-content" },
          [_c("ShowGroupMembers")],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.currentTab == "panel-3"
      ? _c(
          "div",
          { staticClass: "py-5 panel-3 tab-content" },
          [_c("ShowGroupDetails")],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.currentTab == "panel-4"
      ? _c(
          "div",
          { staticClass: "py-5 panel-3 tab-content" },
          [_c("GroupImage", { attrs: { "group-detail": _vm.groupDetails } })],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex items-center justify-center " }, [
      _c("div", {
        staticClass:
          "w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }