var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col items-center" }, [
    _vm.loading
      ? _c("div", { staticClass: "w-full max-w-xs" }, [_vm._m(0)])
      : _c(
          "div",
          [
            _vm.authUser ? _c("div", [_c("NewGroup")], 1) : _vm._e(),
            _vm._v(" "),
            _c("vue-ads-pagination", {
              attrs: {
                "total-items": _vm.totalGroups,
                "max-visible-pages": 5,
                page: _vm.page,
                loading: _vm.totalGroups == 0,
              },
              on: {
                "page-change": _vm.pageChange,
                "range-change": _vm.rangeChange,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (props) {
                    return [
                      _c(
                        "div",
                        { staticClass: "vue-ads-pr-2 vue-ads-leading-loose" },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("message.items")) +
                              " " +
                              _vm._s(props.start) +
                              " " +
                              _vm._s(_vm.$t("message.to")) +
                              " " +
                              _vm._s(props.end) +
                              " " +
                              _vm._s(_vm.$t("message.of")) +
                              " " +
                              _vm._s(props.total) +
                              "\n                "
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "buttons",
                  fn: function (props) {
                    return _vm._l(props.buttons, function (button, key) {
                      return _c(
                        "vue-ads-page-button",
                        _vm._b(
                          {
                            key: key,
                            on: {
                              "page-change": function ($event) {
                                _vm.page = button.page
                              },
                            },
                          },
                          "vue-ads-page-button",
                          button,
                          false
                        )
                      )
                    })
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _vm._l(_vm.groups, function (group, postKey) {
              return _vm.groups != null
                ? _c("Group", {
                    key: group.id,
                    attrs: { groupKey: postKey, group: group },
                    on: { like: _vm.userLike },
                  })
                : _vm._e()
            }),
            _vm._v(" "),
            _c("vue-ads-pagination", {
              attrs: {
                "total-items": _vm.totalGroups,
                "max-visible-pages": 5,
                page: _vm.page,
                loading: _vm.totalGroups == 0,
              },
              on: {
                "page-change": _vm.pageChange,
                "range-change": _vm.rangeChange,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (props) {
                    return [
                      _c(
                        "div",
                        { staticClass: "vue-ads-pr-2 vue-ads-leading-loose" },
                        [
                          _vm._v(
                            "\n                   " +
                              _vm._s(_vm.$t("message.items")) +
                              " " +
                              _vm._s(props.start) +
                              " " +
                              _vm._s(_vm.$t("message.to")) +
                              " " +
                              _vm._s(props.end) +
                              " " +
                              _vm._s(_vm.$t("message.of")) +
                              " " +
                              _vm._s(props.total) +
                              "\n                "
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "buttons",
                  fn: function (props) {
                    return _vm._l(props.buttons, function (button, key) {
                      return _c(
                        "vue-ads-page-button",
                        _vm._b(
                          {
                            key: key,
                            on: {
                              "page-change": function ($event) {
                                _vm.page = button.page
                              },
                            },
                          },
                          "vue-ads-page-button",
                          button,
                          false
                        )
                      )
                    })
                  },
                },
              ]),
            }),
          ],
          2
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex items-center justify-center " }, [
      _c("div", {
        staticClass:
          "w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }