<template>
<div class="grid h-screen place-items-center">
  <div v-if="loading" class="w-full max-w-xs">
<div class="flex items-center justify-center ">
    <div class="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
    </div>
</div>
<div v-else>
    <div class="w-full max-w-xs">
    <form class="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
      <div class="mb-4">
        <label class="block mb-2 text-sm font-bold text-gray-700" for="username">
          {{ $t('message.username') }}
        </label>
        <input v-model="form.username" class="w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Username">
        <p class="text-red-500">{{this.errors.username}}</p>
      </div>
      <div class="mb-4">
        <label class="block mb-2 text-sm font-bold text-gray-700" for="email_address">
          {{ $t('message.emailaddress') }}
        </label>
        <input v-model="form.emailaddress" class="w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="emailaddress" type="text" placeholder="Email address">
          <p class="text-red-500">{{this.errors.emailaddress}}</p>
      </div>
      <div class="mb-4">
        <label class="block mb-2 text-sm font-bold text-gray-700" for="firstname">
          {{ $t('message.firstname') }}
        </label>
        <input v-model="form.firstname" class="w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="firstname" type="text" placeholder="First name">
        <p class="text-red-500">{{this.errors.firstname}}</p>
      </div>
      <div class="mb-4">
        <label class="block mb-2 text-sm font-bold text-gray-700" for="lastname">
          {{ $t('message.lastname') }}
        </label>
        <input v-model="form.lastname" class="w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="lastname" type="text" placeholder="Last name">
      <p class="text-red-500">{{this.errors.lastname}}</p>
      </div>
      <div class="mb-6">
        <label class="block mb-2 text-sm font-bold text-gray-700" for="password">
          {{ $t('message.password') }}
        </label>
        <input v-model="form.password" class="w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="******************">
        <p class="text-red-500">{{this.errors.password}}</p>
      </div>
      <div class="mb-6">
        <label class="block mb-2 text-sm font-bold text-gray-700" for="password">
          {{ $t('message.passwordrepetition') }}
        </label>
        <input v-model="form.password2" class="w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="password2" type="password" placeholder="******************">
        <p class="text-red-500">{{this.errors.password2}}</p>
      </div>
      <div class="flex items-center justify-between">
        <button @click="checkForm" class="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline" type="button">
          {{ $t('message.signup') }}
        </button>
        <router-link to="/login" class="px-6">
        <a class="inline-block text-sm font-bold text-blue-500 align-baseline hover:text-blue-800" href="#">
          {{ $t('message.login') }}?
        </a>
        </router-link>
      </div>
    </form>
  </div>
</div>
</div>
</template>

<script>
import axios from "axios";
export default {
    name: 'Register',
     data(){
        return{
            errors: {
                username: '',
                emailaddress: '',
                firstname: '',
                lastname: '',
                password: '',
                password2: '',
            },
            form: {
                username: '',
                emailaddress: '',
                firstname: '',
                lastname: '',
                password: '',
                password2: '',
            },
            loading : false,
        };
     },
     methods: {
        checkForm: function (e) {
            console.log("Called checkform");

            let error = false;


            console.log("ERROR::"+error);

            if (!this.form.username) {
                this.errors.username=(this.$t('message.namerequired'));
                error= true;
            }
            else {
                this.errors.username='';
            }
  console.log("ERROR::"+error);

            if (!this.form.emailaddress) {
                this.errors.emailaddress=(this.$t('message.emailrequired'));
                error = true;
            } else {
                this.errors.emailaddress='';
            }
  console.log("ERROR::"+error);

            if (!this.validEmail(this.form.emailaddress)) {
                this.errors.emailaddress=(this.$t('message.validemailrequired'));
                error = true;
            } else {
                this.errors.emailaddress=('');
            }
            if (!this.form.firstname) {
                this.errors.firstname=(this.$t('message.firstnamerequired'));
                error = true;
            } else {
                this.errors.firstname='';
            }

            console.log("ERROR::"+error);

            if (!this.form.lastname) {
                this.errors.lastname=(this.$t('message.lastnamerequired'));
                error = true;
            } else {
                this.errors.lastname='';
            }
            this.errors.password='';
            this.errors.password2='';

            if (!this.form.password) {
                this.errors.password=(this.$t('message.passwordrequired'));
                error = true;
            } else {
                this.errors.password='';
            }
            this.errors.password='';
            this.errors.password2='';

            if (!this.form.password2) {
                this.errors.password2=(this.$t('message.passwordvalidationrequired'));
                error = true;
            }
            if (this.form.password!=this.form.password2) {
                this.errors.password=(this.$t('message.thetwopasswordsmustmatch'));
                error = true;
                this.errors.password2=(this.$t('message.thetwopasswordsmustmatch'));
            }
            console.log("ERROR::"+error);
            if (error == true) {
                console.log("Preventing request");
            e.preventDefault();
            } else {
              this.loading = true;
                console.log("Submitting form");
                axios.post('/api/registeruser', this.form)
                            .then(res => {
                              this.loading = false;
                                if (res.data.status=='username_exists') {
                                  alert(this.$t('message.theusernamealreadyexists'));
                                } else
                                if (res.data.status=='email_exists') {
                                  alert(this.$t('message.theemailaddressalreadyexists'));
                                } else {
                                  alert(this.$t('message.youruserregistrationwasuccessful'));
                                  this.$router.push('/login');
                                  }
                            }).catch(error => {
                              this.loading = false;
                               alert("An error occured during registration");
                            })
            }
            },
        validEmail: function (email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
        }

     }

}
</script>

<style>

</style>