import Vue from 'vue';
import Vuex from 'vuex';
import User from './modules/user.js';
import Title from './modules/title.js';
import Profile from './modules/profile.js';
import Posts from './modules/posts.js';
import Users from './modules/users.js';
import FriendRequests from './modules/friendrequests.js';
import Config from './modules/config.js';
import Groups from './modules/groups.js';
import GroupPosts from './modules/groupposts.js';
import JoinRequests from './modules/joinrequests.js';
import GroupMembers from './modules/groupmembers.js';
import Comments from './modules/comments.js';
import AbusedPosts from './modules/abusedposts.js';
import UsersAbusing from './modules/usersabusing.js';

Vue.use(Vuex)


const modules = {
 User, Title, Profile, Posts, Users, FriendRequests, Config, Groups, GroupPosts, JoinRequests, GroupMembers, Comments, AbusedPosts, UsersAbusing
}

export default new Vuex.Store({
    modules
})