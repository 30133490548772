var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tag-input" },
    [
      _vm._l(_vm.mytags, function (tag, index) {
        return _c("div", { key: index, staticClass: "tag-input__tag" }, [
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  return _vm.removeTag(index)
                },
              },
            },
            [_vm._v("x")]
          ),
          _vm._v("\n    " + _vm._s(tag) + "\n  "),
        ])
      }),
      _vm._v(" "),
      _c("input", {
        ref: "that",
        staticClass: "tag-input__text",
        attrs: { type: "text", placeholder: "Enter a Tag" },
        on: {
          keydown: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.addTag.apply(null, arguments)
            },
            function ($event) {
              if (!$event.type.indexOf("key") && $event.keyCode !== 188) {
                return null
              }
              return _vm.addTag.apply(null, arguments)
            },
          ],
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }