var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid h-screen place-items-center" }, [
    _c("div", { staticClass: "w-full max-w-xs" }, [
      _c(
        "form",
        { staticClass: "px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md" },
        [
          _c("p", [_vm._v("Reset password")]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.error))]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-4" }, [
            _c(
              "label",
              {
                staticClass: "block mb-2 text-sm font-bold text-gray-700",
                attrs: { for: "username" },
              },
              [
                _vm._v(
                  "\n       " +
                    _vm._s(_vm.$t("message.emailaddress")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.username_email,
                  expression: "form.username_email",
                },
              ],
              staticClass:
                "w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
              attrs: { id: "username", type: "text", placeholder: "Email" },
              domProps: { value: _vm.form.username_email },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "username_email", $event.target.value)
                  },
                  _vm.resetError,
                ],
              },
            }),
            _vm._v(" "),
            _c("p", { staticClass: "text-red-500" }, [
              _vm._v(_vm._s(this.errors.username_email)),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex items-center justify-between" },
            [
              _c(
                "button",
                {
                  staticClass:
                    "px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline",
                  attrs: { type: "button" },
                  on: { click: _vm.checkForm },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("message.resetpassword")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { staticClass: "px-6", attrs: { to: "/login" } },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "inline-block text-sm font-bold text-blue-500 align-baseline hover:text-blue-800",
                      attrs: { href: "#" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("message.login")) +
                          "?\n      "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }