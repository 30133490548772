<template>
<div>
<form class="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
    <div class="mb-4">
      <label class="block mb-2 text-sm font-bold text-gray-700" for="tags">
          Tags
      </label>

      <TagInput :addurl="'/api/addgrouptag/'+this.$route.params.id" :removeurl="'/api/removegrouptag/'+this.$route.params.id" :mytags="tags" :userid="authUser.id" />
    </div>
      <div class="mb-4">
      <label class="block mb-2 text-sm font-bold text-gray-700" for="tags">
          Group name
      </label>

      <input @keyup="userinput" name="name" v-model="profileData.name" class="w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="website" type="text" placeholder="http://www.supermod.net">
      </div>
      <div class="mb-4">
      <label class="block mb-2 text-sm font-bold text-gray-700" for="tags">
          Group description
      </label>

      <textarea @keyup="userinput" name="description" class="w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="website" type="text" placeholder="Description" v-model="profileData.description"></textarea>
    </div>
  </form>
</div>
</template>

<script>
import TagInput from "../Profile/TagInput";
import axios from "axios";
import { mapGetters } from 'vuex';

 export default {
 name: "ShowGroupDetails",
     data: function () {
        return {
            tags: [],
            profileData: [],
        };
     },
 components: {

         TagInput,
    },
    mounted() {
        axios.get('/api/groupdata/'+this.$route.params.id)
                                    .then(res => {
                                            if (res.data.tags) {
                                                console.log("!!!!ETTING TAGS");
                                                console.log(res.data.tags);
                                             this.tags= res.data.tags;
                                            }
                                            this.profileData = res.data;
                                            console.error("MYTAGSxyad");
                                            console.error(this.tags);
                                    }).catch(error => {
                                        console.log(error);
                                    alert("A network error occured");
                                    })
    },
    methods: {
        userinput(event) {
            let val = event.target.value;
            let targetName = event.target.name;
            console.log("TNAME:"+targetName);
            axios.post('/api/setgroupinput/'+this.$route.params.id, { value: val, name: targetName })
                            .then(res => {
                            }).catch(error => {
                            })

        },
    },
    computed: {
        ...mapGetters({
            authUser: 'authUser',
        }),
    }
}
</script>

<style>

</style>