import axios from "axios"

const state = {
    user: null,
    userStatus : null,
    posts : null,
    postsStatus : null,
    userTotalPosts : 1
}

const getters = {
    posts: state => {
        return state.posts;
    },
    user: state => {
        return state.user
    },
    friendButtonText: (state, getters, rootState) => {
        console.error(state.user.friendship);
        if ( state.user==null || state.user.friendship == '' || state.user.friendship == 'none') {
            return('');
        } else
        if (state.user.friendship == "no_friendship") {
            return('Add friend');
        } if (state.user.friendship == 'accept') {
            return('Accept');
        } else if (state.user.friendship == 'confirmed') {
            return('Confirmed');
        } else {
            return('Pending Friend Request');
        }
    },
    status : state => {
        return {
           'user' : state.userStatus,
           'posts' : state.postsStatus,
        }
    },
    friendship: state => {
        console.log("satate.user.:"+state.user.friendship);
        console.log(state.user);
        return state.user.friendship;
    },
    userTotalPosts : state => {
        return state.userTotalPosts;
    }
}


const actions = {
    fetchUser({
        commit, dispatch
    }, userId) {
        console.log("GGETTING USER2");
        commit('setUserStatus', 'loading');
        axios.get('/api/user/'+userId).then(resp => {
            if (resp.data.status!='error') {
                commit('setUser', resp.data);
            } else {
                alert('This user does not exist');
            }
        console.log(resp.data);
        commit('setUserStatus', 'success');
        }).catch(error => {
            console.log(error);
            console.log('Unable to fetch user');
        }).finally(() => {
            commit('setUserStatus', 'error');
        });
    },


    fetchUserPosts({
        commit, dispatch
    }, data) {
        console.error("DATA.PAGE:"+data.page);
        axios.get('/api/posts/'+data.userId+'/'+data.page).then(resp => {
            console.log("GOT REAL POSTS"+data.userId+"/"+data.page);
            commit('setPosts', resp.data);
            commit('setPostsStatus', 'success');
            data.that.loading = false;
        }).catch(error => {
            console.log(error);
            console.log('Unable to fetch standard posts');
            commit('setPostsStatus', 'error');
            data.that.loading = false;
        });
},  fetchUserTotalPosts({
    commit, dispatch
}, userId) {

    axios.get('/api/totaluserposts/'+userId).then(resp => {
        console.log("GOT TOTALPOSTS");
        commit('setUserTotalPosts', resp.data.userTotalPosts);
    }).catch(error => {
        console.log(error);
        console.log('Unable to fetch total user posts');
    });
},
    blockUser({ commit, state }, userId) {
        axios.post('/api/blockuser', { 'user_id' : userId }).then(res => {
            if (res.data.status=='ok') {
            alert('This user is going to be blocked');
            } else {
                alert(res.data.details);
            }
        }).catch(error => {
            console.log("222");
        })
    },
    sendFriendRequest({ commit, state }, friendId) {

        axios.post('/api/friend-request', { 'friend_id' : friendId }).then(res => {
            console.log("friendship");
            console.log(res.data);
            console.log(res.data.friendship);
            if (res.data.friendship)
            commit('setUserFriendship', res.data.friendship);
        }).catch(error => {
            console.log("222");
        })
    },
    acceptFriendRequest({ commit, state }, userId) {

        axios.post('/api/friend-request-response', { 'user_id' :userId }).then(res => {
            console.log("friendship");
            console.log(res.data);
            console.log(res.data.friendship);
            if (res.data.friendship)
            commit('setUserFriendship', res.data.friendship);
        }).catch(error => {
            console.log("222");
        })
    },
    removeFriendRequest({ commit, state }, userId) {

        axios.post('/api/friend-request/delete', { 'user_id' :userId }).then(res => {
            console.log("friendship");
            console.log(res.data);
            console.log(res.data.friendship);
            commit('setUserFriendship', 'no_friendship');
        }).catch(error => {
            console.log("222");
        })
    },
    ignoreFriendRequest({ commit, state }, userId) {

        axios.delete('/api/friend-request/delete', { data : { 'user_id' : userId } }).then(res => {

            if (res.data.friendship) {
                console.log("SETTING FRIENDSHIP TO "+res.data.friendship);
            commit('setUserFriendship', res.data.friendship);
            } else {
                console.error("NOTHING");
            }
        }).catch(error => {
            console.log("222");
        })
    },
    likeProfile({commit, state}, data) {
        axios.post('/api/posts/like/'+data.postId)
        .then(res => {
          commit('pushProfileLikes', { likes: res.data, postKey: data.postKey });

        }).catch(error => {
        })
    },

    /*setUser(context, data) {
        console.log("USER");
        context.commit('setUser', data)
    }*/
}

const mutations = {
    setUser(state, user) {
        state.user = user;
    },
    setPosts(state, posts) {
        console.error("SETTINGG POSTS");
        console.log(posts);
        state.posts = posts;
    },
    setUserTotalPosts(state, totalPosts) {
        console.error("SETTINGG USER TOTAL POSTS"+totalPosts);
        state.userTotalPosts = totalPosts;
    },
    setUserStatus(state, status) {
        state.userStatus = status;
    },
    setPostsStatus(state, status) {
        state.postsStatus = status;
    },
    setUserFriendship(state, friendship) {
        state.user.friendship = friendship;
    },
    pushProfileLikes(state, data) {

      state.posts[data.postKey].likesLength=data.likes.likesLength;
    },
}
export default {
     state, getters, mutations, actions
}