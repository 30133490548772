<template>

 <div class="flex flex-col items-center">


<div v-if="loading" class="w-full max-w-xs">
<div class="flex items-center justify-center ">
    <div class="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
    </div>
    </div>
<div v-else>

<vue-ads-pagination
            :total-items="userTotalPosts"
            :max-visible-pages="5"
            :page="page"
            :loading="userTotalPosts == 0"
            @page-change="pageChange"
            @range-change="rangeChange"
        >
            <template slot-scope="props">
                <div class="vue-ads-pr-2 vue-ads-leading-loose">
                    {{ $t('message.items') }} {{ props.start }} {{ $t('message.to') }} {{ props.end }} {{ $t('message.of') }} {{ props.total }}
                </div>
            </template>
            <template
                slot="buttons"
                slot-scope="props"
            >
                <vue-ads-page-button
                    v-for="(button, key) in props.buttons"
                    :key="key"
                    v-bind="button"
                    @page-change="page = button.page"
                />
            </template>
        </vue-ads-pagination>
     <div v-if="user">
        <div class="relative w-full">
                <div  class="z-10 w-full h-64 pb-4 overflow-hidden w-100">
                    <router-link :to="'/showprofile/'+user.id">
                        <img :src="user.bannerUri" class="object-cover w-full">
                    </router-link>
                </div>
        </div>
        <div class="relative bottom-0 left-0 z-20 flex items-center -mt-12">
            <div v-if="user">
                <router-link :to="'/showprofile/'+user.id">
                    <img :src="user.avatarUri" alt="user profile image" class="object-cover w-32 border-4 border-gray-200 rounded-full shadow-lg">
                </router-link>
            </div>
            <p v-if="user != null" class="ml-4 text-2xl text-gray-700">{{ user.userName }}</p>
        </div>
        <div v-if="(user) &&authUser  && (user.id!=authUser.id) && friendButtonText !== ''">
            <div v-if="friendButtonText !== 'Confirmed'">
                <div v-if="friendButtonText !== 'Accept'" class="bottom-0 right-0 z-20 flex items-center mb-4 ml-12">
                    <button class="px-3 py-1 bg-blue-500 rounded" @click="$store.dispatch('sendFriendRequest', $route.params.userId)">{{ friendButtonText }}</button>
                </div>
                <div v-if="friendButtonText === 'Accept'" class="bottom-0 right-0 z-20 flex items-center mb-4 ml-12">
                    <button class="px-3 py-1 bg-gray-400 rounded" @click="$store.dispatch('acceptFriendRequest', $route.params.userId)">Accept</button>
                </div>
                <div v-if="friendButtonText === 'Accept'" class="bottom-0 right-0 z-20 flex items-center mb-4 ml-12">
                    <button class="px-3 py-1 bg-gray-400 rounded" @click="$store.dispatch('ignoreFriendRequest', $route.params.userId)">Ignore</button>
                </div>
            </div>
            <div v-else>
                <div class="bottom-0 right-0 z-20 flex items-center mb-4 ml-12">
                    <button class="px-3 py-1 bg-gray-400 rounded" @click="removeFriendship();">Friendship! (Remove friendship)</button>
                </div>
            </div>
        </div>
        <div>
           <button v-if="(authUser) && (user.id != authUser.id)" class="px-3 py-1 bg-blue-500 rounded" @click="blockUser($route.params.userId)">Remove/block user</button>
        </div>
    </div>
     <Post v-for="(post, postKey) in posts" :key="post.id" :postKey="postKey" @like="userLike" :post="post" />
      <vue-ads-pagination
            :total-items="userTotalPosts"
            :max-visible-pages="5"
            :page="page"
            :loading="userTotalPosts == 0"
            @page-change="pageChange"
            @range-change="rangeChange"
        >
            <template slot-scope="props">
                <div class="vue-ads-pr-2 vue-ads-leading-loose">
                    {{ $t('message.items') }} {{ props.start }} {{ $t('message.to') }} {{ props.end }} {{ $t('message.of') }} {{ props.total }}
                </div>
            </template>
            <template
                slot="buttons"
                slot-scope="props"
            >
                <vue-ads-page-button
                    v-for="(button, key) in props.buttons"
                    :key="key"
                    v-bind="button"
                    @page-change="page = button.page"
                />
            </template>
        </vue-ads-pagination>

     <p v-if="posts == null || posts.length == 0">{{ $t('message.nopostsfoundgetstarted') }}</p>
</div>
 </div>
</template>

<script>
import '../../../node_modules/@fortawesome/fontawesome-free/css/all.css';
import '../../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';

import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';

import Post from '../Post';
import { mapGetters } from 'vuex';
export default {
    name: "Show",
    data: () => {
        return {
            loading : true,
            page: 0,
        }
    },
    mounted() {
        this.$store.dispatch('fetchUser', this.$route.params.userId);
        this.$store.dispatch('fetchUserTotalPosts', this.$route.params.userId);
        this.$store.dispatch("fetchUserPosts", { userId: this.$route.params.userId, page: this.page, that: this });
    },
    computed: {

        ...mapGetters({
                authUser: 'authUser',
                user: 'user',
                friendButtonText: 'friendButtonText',
                posts: 'posts',
                status : 'status',
                userTotalPosts: 'userTotalPosts',
        })
    },
    components: {
        Post,
        VueAdsPagination,
        VueAdsPageButton,
    },
    methods: {
        removeFriendship() {
            if (confirm('Do you really want hide the posts from your friend again?')) {
            this.$store.dispatch('removeFriendRequest', this.$route.params.userId)
            }
        },
        blockUser(userId) {
            if (confirm('Are you sure you want to block this user?')) {
                this.$store.dispatch('blockUser', userId);
            }
        },
        userLike(data) {
            this.$store.dispatch('likeProfile', data);
            console.log(data);
        },
        pageChange (page) {
            console.log("FETCH"+this.page);
            this.page = page;
            this.$store.dispatch('fetchNewsPosts', { page: this.page, that: this });
            console.log(page);
        },

        rangeChange (start, end) {
            let page = 0;
            if (start > 0) {
                page = Math.floor(start / 10);
            }
            this.$store.dispatch("fetchUserPosts", { userId: this.$route.params.userId, page: this.page, that: this });
            this.$emit('scrollToTop');
        }
    },
}
</script>

<style>

</style>
