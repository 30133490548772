<template>
 <div class="mt-6 overflow-hidden bg-white rounded shadow">
        <div class="flex flex-col p-4">

            <div class="flex items-center">
                <router-link :to="'/'+user.userName" class="px-6">
                <div class="w-8">
                    <img :src="user.avatarUri" class="object-cover w-8 h-8 rounded-full">
                </div>
                <div class="ml-4">
                    <div>{{ user.displayName }}</div>
                </div>
                 <div class="ml-4">
                    <div>({{ user.postSize }} {{ $t('message.posts') }})</div>
                </div>
                 </router-link>
                <div v-if="authUser" class="ml-4" @click="reportUser(user.id)">
                    Report User
                </div>
            </div>

        </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from 'vuex';
export default {
    name: "User",
    props: ["user"],
      computed: {
        ...mapGetters({
                authUser: 'authUser',
        })
    },
    methods: {
        reportUser(id) {
            let description = prompt('Please provide some more information or leave it empty (max. 255 characters)', '');
            if (description!=null) {
            axios.post('/api/reportuser', { 'user_id' : id, 'description' : description })
                                .then(res => {
                                    alert('This user has been reported');
                                }).catch(error => {
                                    alert('An error occured - maybe not logged in?');
                                    console.error("An error occured reporting the user");
                                });

            }

        }
    }

}
</script>

<style>

</style>