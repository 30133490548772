var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p-4 mt-4 bg-white rounded shadow " }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "w-full max-w-xs mt-32 mb-12",
      },
      [_vm._m(0)]
    ),
    _vm._v(" "),
    !_vm.loading
      ? _c("div", [
          _c("div", [
            _c("p", [_vm._v(_vm._s(_vm.$t("message.youareowner")))]),
            _vm._v(" "),
            _vm.groupDetails.numberOfJoinRequests > 0
              ? _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("message.youhave")) +
                      " " +
                      _vm._s(_vm.groupDetails.numberOfJoinRequests) +
                      " " +
                      _vm._s(_vm.$t("message.joinrequests")) +
                      "."
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.groupDetails.numberOfJoinRequests > 0
              ? _c(
                  "p",
                  { staticClass: "text-blue-700" },
                  [
                    _c("router-link", { attrs: { to: "/joinrequests" } }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("message.clickhere")) +
                          "\n                        "
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex items-center justify-center " }, [
      _c("div", {
        staticClass:
          "w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }