import Vue from 'vue'
import VueI18n from 'vue-i18n';
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css'
Vue.use(VueI18n);

const messages = {
    en: {
      message: {
        welcometothissocialnetwork: 'Welcome to this social network',
        pleasesignup: 'Please sign up for an account!',
        userlist: 'User list',
        register: 'Sign up',
        legalnotice: 'Legal notice',
        home: 'Home',
        username: 'Username',
        emailaddress: 'Email address',
        firstname: 'First name',
        lastname: 'Last name',
        password: 'Password',
        passwordrepetition: 'Password repetition',
        signup: 'Register',
        login: 'Login',
        dropfiles: 'Place image/mp4 here for upload, max. 25MB',
        avatarDropfiles: 'Place avatar here, max. 1MB',
        like: 'like',
        tocomment: 'comment',
        reportabuse: 'Report abuse',
        comments: 'comments',
        comment: 'comment',
        nofriendrequests: 'No friend requests - invite others!',
        nojoinrequests: 'No join requests - join other groups!',
        items: 'list',
        to : 'to',
        of: 'of',
        successfullyloggedout: 'Successfully logged out',
        anerroroccureduploadingthefile: 'An error occured uploading the file, image file limit is 1MB',
        usernameoremailaddressrequired: 'Email address is required',
        passwordrequired: 'Password required',
        wrongemailorpassword: 'Wrong email or password, please try again',
        namerequired: 'Name required',
        emailrequired: 'Email required',
        validemailrequired: 'Valid email required',
        firstnamerequired: 'First name required',
        lastnamerequired: 'Last name required',
        passwordrequired: 'Password required',
        passwordvalidationrequired: 'Passwort validation required',
        thetwopasswordsmustmatch: 'The two passwords must match',
        theemailaddressalreadyexists: 'The email address already exists',
        youruserregistrationwasuccessful: 'Your user registration was successful',
        anerroroccuredduringregistration: 'An error occured during registration',
        post: 'Post',
        profile: 'Profil',
        friendrequests: 'Friend requests',
        posts: 'posts',
        nopostsfoundgetstarted: 'No posts found. Get started!',
        dropzone: 'Drag and drop image/mp4 here (max. 25MB)',
        addapost: 'Add a post',
        dopost: 'Post',
        resetpasswordquestion: 'Forgotten password?',
        resetpassword: 'Reset',
        wrongemail: 'Wrong email address',
        passwordreset: 'Your password has been reset, please check your email',
        markamP: 'Please mark on the map where you are located:',
        totalkb : 'Currently uploaded',
        groups: 'Groups',
        addagroup: 'Add group',
        docreate: 'Create',
        reason: 'Reason',
        send: 'Send',
        youarewaiting : 'You are waiting for confirmation to access write access to this group.',
        tothegroup: 'To the group',
        youareowner: 'You are owner of the group',
        youhave: 'You have',
        joinrequests: 'join requests for this group',
        clickhere: 'Click here to handle your join requests',
        joinsuccess: 'You have successfully created a join request, please wait and reload the page.',
        remove: 'Remove',
        nogroupmembers: 'No group members - please wait for them',
        theusernamealreadyexists: 'The username already exists, please type a different one',
        newimage : 'New image',
        currentimage: 'Current image',
        bannerDropfiles: 'Place the image for the banner here'
      }
    },
    de: {
      message: {
        welcometothissocialnetwork: 'Willkommen zu diesem sozialen Netzwerk',
        pleasesignup: 'Bitte registrieren Sie sich für einen Account!',
        userlist: 'Benutzerliste',
        register: 'Registrieren',
        legalnotice: 'Impressum',
        home: 'Übersicht',
        username: 'Benutzername',
        emailaddress: 'Email Adresse',
        firstname: 'Vorname',
        lastname: 'Nachname',
        password: 'Passwort',
        passwordrepetition: 'Passwort Wiederholung',
        signup: 'Registrieren',
        login: 'Einloggen',
        dropfiles: 'Bilder hier ablegen zum Upload',
        avatarDropfiles: 'Avatar hier ablegen, max. 1MB',
        like: 'liken',
        tocomment: 'kommentieren',
        reportabuse: 'Missbrauch melden',
        comment: 'Kommentar',
        comments: 'Kommentare',
        nofriendrequests: 'Keine Freundschaftsanfragen - lade andere ein!',
        nojoinrequests: 'Keine Gruppenanfragen - trage Dich in anderen Gruppen ein!',
        items: 'Liste',
        to : 'bis',
        of: 'von',
        successfullyloggedout: 'Erfolgreich ausgeloggt',
        anerroroccureduploadingthefile: 'Ein Fehler ist beim Upload entstanden, maximale Groesse der Datei ist 1MB',
        usernameoremailaddressrequired: 'Email Adresse wird benoetigt',
        passwordrequired: 'Passwort notwendig',
        passwordvalidationrequired: 'Passwort Validierung notwendig',
        wrongemailorpassword: 'Falsche email oder Passwort, bitte nochmal versuchen',
        namerequired: 'Name notwendig',
        emailrequired: 'Email notwendig',
        validemailrequired: 'Gültige email notwendig',
        firstnamerequired: 'Vorname notwendig',
        lastnamerequired: 'Nachname notwendig',
        passwordrequired: 'Passwort notwendig',
        thetwopasswordsmustmatch: 'Die beiden Passwoerter muessen uebereinstimmen',
        theemailaddressalreadyexists: 'Die Email Adresse existiert schon',
        youruserregistrationwasuccessful: 'Ihre Registrierung war erfolgreich',
        anerroroccuredduringregistration: 'Ein Fehler ist waehrend der Registrierungg entstanden',
        post: 'Absenden',
        profile: 'Profil',
        friendrequests: 'Freundschaftsanfragen',
        posts: 'Nachrichten',
        nopostsfoundgetstarted: 'Keine Nachrichten gefunden. Lege Du los!',
        dropzone: 'Bild/mp4 hier hochladen (drag und drop, max. 25MB)',
        addapost: 'Nachricht hinzufügen',
        dopost: 'Absenden',
        resetpasswordquestion: 'Passwort vergessen?',
        resetpassword: 'Zurücksetzen',
        wrongemail: 'Falsche email Adresse',
        passwordreset: 'Das Passwort wurde zurückgesetzt, bitte email Postfach überprüfen',
        markmap: 'Bitte markieren Sie auf der Karte, wo Sie sich befinden:',
        totalkb : 'Aktuell geuploaded',
        groups: 'Gruppen',
        addagroup: 'Gruppe hinzufügen',
        docreate: 'Erstellen',
        reason : 'Grund',
        send: 'Absenden',
        youarewaiting : 'Sie warten auf eine Bestätigung für Schreibzugriff auf diese Gruppe.',
        tothegroup: 'In die Gruppe',
        youareowner: 'Sie sind Besitzer dieser Gruppe',
        youhave: 'Sie haben',
        joinrequests: 'Teilnahmeanfragen für diese Gruppe',
        clickhere: 'Klicken Sie hier um diese zu bearbeiten',
        joinsuccess: 'Sie haben erfolgreich eine Teilnahmeanfrage erstellt, bitte warten Sie und laden Sie die Seite neu',
        remove: 'Entfernen',
        nogroupmembers: 'Keine Gruppenmitglieder - bitte warte auf welche',
        theusernamealreadyexists: 'Der Benutzername existiert bereits, bitte geben Sie einen neuen ein',
        newimage : 'Neues Bild',
        currentimage: 'Aktuelles Bild',
        bannerDropfiles: 'Bild für Banner hier hochladen'
      }
    }
  };

import Vuex from 'vuex'
//import Vuetify from 'vuetify';
import Routes from './routes.js';
import App from './App';
//import 'vuetify/dist/vuetify.min.css'
import store from './store';

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(VuePlyr, {
  plyr: {}
});
//Vue.use(Vuetify);

const i18n = new VueI18n({
    locale: 'de',
    messages
  });

const app = new Vue({
    //vuetify: new Vuetify(),
    store,
    i18n,
    el: '#app',
    router: Routes,
    render: h => h(App)
});


export default app;