var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bg-white" }, [
      _c("nav", { staticClass: "flex flex-col tabs sm:flex-row" }, [
        _c(
          "button",
          {
            staticClass:
              "block px-6 py-4 font-medium text-gray-600 text-blue-500 tab hover:text-blue-500 focus:outline-none",
            class:
              _vm.currentTab == "panel-1" ? "border-b-2 border-blue-500" : "",
            attrs: { "data-target": "panel-1" },
            on: { click: _vm.onTabClick },
          },
          [
            _vm._v(
              "\n                                Description\n                            "
            ),
          ]
        ),
        _c(
          "button",
          {
            staticClass:
              "block px-6 py-4 tab ext-gray-600 hover:text-blue-500 focus:outline-none",
            class:
              _vm.currentTab == "panel-2" ? "border-b-2 border-blue-500" : "",
            attrs: { "data-target": "panel-2" },
            on: { click: _vm.onTabClick },
          },
          [
            _vm._v(
              "\n                                Edit profile\n                            "
            ),
          ]
        ),
        _c(
          "button",
          {
            staticClass:
              "block px-6 py-4 text-gray-600 tab hover:text-blue-500 focus:outline-none",
            class:
              _vm.currentTab == "panel-3" ? "border-b-2 border-blue-500" : "",
            attrs: { "data-target": "panel-3" },
            on: { click: _vm.onTabClick },
          },
          [
            _vm._v(
              "\n                                Map\n                            "
            ),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { id: "panels" } },
      [
        _c("ProfileDetails", {
          attrs: {
            authUser: _vm.authUser,
            currentTab: _vm.currentTab,
            profileData: _vm.profileData,
            showMoreFriends: _vm.showMoreFriends,
            tagString: _vm.tagString,
          },
        }),
        _vm._v(" "),
        _vm.currentTab == "panel-2"
          ? _c("div", { staticClass: "py-5 panel-2 tab-content" }, [
              _c(
                "form",
                {
                  staticClass: "px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md",
                },
                [
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c(
                        "label",
                        {
                          staticClass:
                            "block mb-2 text-sm font-bold text-gray-700",
                          attrs: { for: "tags" },
                        },
                        [_vm._v("\n          Tags\n      ")]
                      ),
                      _vm._v(" "),
                      _c("TagInput", {
                        attrs: {
                          addurl: "/api/addtag/" + _vm.authUser.id,
                          removeurl: "/api/removetag/" + _vm.authUser.id,
                          mytags: _vm.profileData.tags,
                          userid: _vm.authUser.id,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-4" }, [
                    _c(
                      "label",
                      {
                        staticClass:
                          "block mb-2 text-sm font-bold text-gray-700",
                        attrs: { for: "tags" },
                      },
                      [_vm._v("\n          Website URL\n      ")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.profileData.website,
                          expression: "profileData.website",
                        },
                      ],
                      staticClass:
                        "w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
                      attrs: {
                        id: "website",
                        type: "text",
                        placeholder: "http://www.supermod.net",
                      },
                      domProps: { value: _vm.profileData.website },
                      on: {
                        keyup: _vm.userinput,
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.profileData,
                            "website",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-col items-center pt-4" }, [
                _c("div", { staticClass: "p-4 bg-white rounded shadow" }, [
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-between" },
                    [
                      _c("div", [
                        _vm.authUser
                          ? _c("img", {
                              staticClass: "object-cover w-8 h-8 rounded-full",
                              attrs: { src: _vm.authUser.avatarUri },
                            })
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "p-4 bg-white rounded shadow " }, [
                  _c(
                    "div",
                    { staticClass: "m-4" },
                    [
                      _c("vue-dropzone", {
                        ref: "myVueDropzone",
                        attrs: { id: "dropzone", options: _vm.dropzoneOptions },
                        on: { "vdropzone-success": _vm.added },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-col items-center pt-4" }, [
                _c("div", { staticClass: "p-4 bg-white rounded shadow" }, [
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-between" },
                    [
                      _c("div", [
                        _vm.authUser
                          ? _c("img", {
                              staticClass: "object-cover w-8 h-8",
                              attrs: { src: _vm.authUser.bannerUri },
                            })
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "p-4 bg-white" }, [
                  _c(
                    "div",
                    { staticClass: "m-4" },
                    [
                      _c("vue-dropzone", {
                        ref: "myVueBannerDropzone",
                        attrs: {
                          id: "bannerDropzone",
                          options: _vm.bannerDropzoneOptions,
                        },
                        on: { "vdropzone-success": _vm.added },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.currentTab == "panel-3"
          ? _c("div", { staticClass: "py-5 panel-3 tab-content" }, [
              _c(
                "div",
                { staticClass: "w-2/3 overflow-x-hidden" },
                [
                  _vm._v(
                    "\n                                " +
                      _vm._s(_vm.$t("message.markmap")) +
                      "\n                            "
                  ),
                  _c(
                    "l-map",
                    {
                      ref: "map",
                      staticClass: "w-1/3 overflow-x-hidden map",
                      attrs: { center: _vm.center, zoom: _vm.zoom },
                      on: {
                        "update:zoom": _vm.zoomUpdated,
                        "update:center": _vm.centerUpdated,
                        click: _vm.addMarker,
                      },
                    },
                    [
                      _vm._l(_vm.markers, function (marker, index) {
                        return _c("l-marker", {
                          key: index,
                          attrs: { "lat-lng": marker },
                          on: {
                            click: function ($event) {
                              return _vm.removeMarker(index)
                            },
                          },
                        })
                      }),
                      _vm._v(" "),
                      _c("l-tile-layer", { attrs: { url: _vm.url } }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }