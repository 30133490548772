var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-6 overflow-hidden bg-white rounded shadow" },
    [
      _c(
        "div",
        { staticClass: "flex flex-col p-4" },
        [
          _c(
            "router-link",
            {
              staticClass: "px-6",
              attrs: { to: "/" + _vm.friendRequest.userId },
            },
            [
              _c("div", { staticClass: "flex items-center" }, [
                _c("div", { staticClass: "w-8" }, [
                  _c("img", { attrs: { src: _vm.friendRequest.avatarUri } }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ml-6" }, [
                  _c("div", [_vm._v(_vm._s(_vm.friendRequest.userName))]),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }