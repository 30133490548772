import axios from "axios"

const state = {
    abusedPosts: null,
    totalAbusedPosts : 0
}

const getters = {
  abusedPosts : state => {
      return state.abusedPosts
  },
  totalAbusedPosts: state => {
    return state.totalAbusedPosts
  }
}


const actions = {
  fetchAbusedPosts({ commit, state }, data) {
    console.error("DATA.PAGE2:"+data.page);
    axios.get('/api/abusedposts/'+data.page).then(resp => {
      commit('setAbusedPosts', resp.data);
    data.that.loading = false;
  }).catch(error => {
   data.that.loading = false;
  });
},
getTotalAbusedPosts({commit, state}) {
  axios.get('/api/totalabusedposts').then(resp => {
    console.log("GOT TOTALPOSTS");
    console.log(resp.data);
    console.log(resp.data.totalPosts);
    commit('updateTotalAbusedPosts', resp.data.totalAbusedPosts);
    }).catch(error => {
    });
},
removeAbusedPost({commit, state}, id) {
   commit('removeAbusedPost', id);
}

}

  const mutations = {
    setAbusedPosts(state, posts) {
      state.abusedPosts = posts;
    },
    updateTotalAbusedPosts(state, totalPosts) {
      state.totalPosts = totalPosts;
    },
    removeAbusedPost(state, id) {
      let delIndex = -1;
      for (var i = 0; i < state.newsPosts.length; i++) {
        console.log(state.newsPosts[i].id+"=="+id);
          if (state.newsPosts[i].id==id) {
              delIndex = i;
          }
      }
      if (delIndex != -1) {
      state.newsPosts.splice(delIndex, 1);
      }
    }
  }

  export default {
    state, getters, actions, mutations
  }