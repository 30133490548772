var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bg-gray-200 p-[1.5px] rounded-full" }, [
      _c("img", {
        staticClass: "p-1 bg-white rounded-full ",
        attrs: { src: _vm.img, alt: "" },
      }),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "w-20 text-xs text-center truncate" }, [
      _vm._v(_vm._s(_vm.username)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }