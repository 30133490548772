<template>

 <div class="flex flex-col items-center">

  <vue-ads-pagination
            :total-items="totalFriendRequests"
            :max-visible-pages="5"
            :page="page"
            :loading="totalFriendRequests == 0"
            @page-change="pageChange"
            @range-change="rangeChange"
        >
            <template slot-scope="props">
                <div class="vue-ads-pr-2 vue-ads-leading-loose">
                    {{ $t('message.items') }} {{ props.start }} {{ $t('message.to') }} {{ props.end }} {{ $t('message.of') }} {{ props.total }}
                </div>
            </template>
            <template
                slot="buttons"
                slot-scope="props"
            >
                <vue-ads-page-button
                    v-for="(button, key) in props.buttons"
                    :key="key"
                    v-bind="button"
                    @page-change="page = button.page"
                />
            </template>
        </vue-ads-pagination>
        <div v-if="friendRequestsList.length > 0">
        <FriendRequest v-for="(friendRequest, friendRequestKey) in friendRequestsList" :key="friendRequestKey" :friendRequest="friendRequest" />
   </div>
   <div v-else>
       {{ $t('message.nofriendrequests') }}
    </div>
    <vue-ads-pagination
            :total-items="totalFriendRequests"
            :max-visible-pages="5"
            :page="page"
            :loading="totalFriendRequests == 0"
            @page-change="pageChange"
            @range-change="rangeChange"
        >
            <template slot-scope="props">
                <div class="vue-ads-pr-2 vue-ads-leading-loose">
                    {{ $t('message.items') }} {{ props.start }} {{ $t('message.to') }} {{ props.end }} {{ $t('message.of') }} {{ props.total }}
                </div>
            </template>
            <template
                slot="buttons"
                slot-scope="props"
            >
                <vue-ads-page-button
                    v-for="(button, key) in props.buttons"
                    :key="key"
                    v-bind="button"
                    @page-change="page = button.page"
                />
            </template>
        </vue-ads-pagination>
  </div>
</template>

<script>
import '../../node_modules/@fortawesome/fontawesome-free/css/all.css';
import '../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';

import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import FriendRequest from './FriendRequest/FriendRequest';
import { mapGetters } from 'vuex';
export default {
    name: 'FriendRequests',
    components: {
        FriendRequest,
        VueAdsPagination,
        VueAdsPageButton,
    },
    computed: {
        ...mapGetters({
            friendRequestsList: 'friendRequestsList',
            totalFriendRequests: 'totalFriendRequests',
        }),
    },
    methods: {
        pageChange (page) {
        },
        rangeChange (start, end) {
            let page = 0;
            if (start > 0) {
                page = Math.floor(start / 10);
            }
            this.$store.dispatch('fetchFriendRequests', page);
            this.$emit('scrollToTop');
        }
    },
    mounted() {
      console.log("GETTING POSTS2");
      this.$store.dispatch('fetchFriendRequests', this.page);
      this.$store.dispatch('getTotalFriendRequests');
    }, data() {
      return {
        page: 0,
        loading: false,
      }
    }

}
</script>

<style>

</style>