import { render, staticRenderFns } from "./NewPost.vue?vue&type=template&id=3623ab35&"
import script from "./NewPost.vue?vue&type=script&lang=js&"
export * from "./NewPost.vue?vue&type=script&lang=js&"
import style0 from "./NewPost.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/x/learnsymfony/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3623ab35')) {
      api.createRecord('3623ab35', component.options)
    } else {
      api.reload('3623ab35', component.options)
    }
    module.hot.accept("./NewPost.vue?vue&type=template&id=3623ab35&", function () {
      api.rerender('3623ab35', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/components/NewPost.vue"
export default component.exports