<template>
 <div class="flex flex-col items-center">

<div v-if="loading" class="w-full max-w-xs">

    <div class="flex items-center justify-center ">
        <div class="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
    </div>
</div>
<div v-else>

     <div v-if="authUser">
  <NewGroup />
  </div>
  <vue-ads-pagination
            :total-items="totalGroups"
            :max-visible-pages="5"
            :page="page"
            :loading="totalGroups == 0"
            @page-change="pageChange"
            @range-change="rangeChange"
        >
            <template slot-scope="props">
                <div class="vue-ads-pr-2 vue-ads-leading-loose">
                    {{ $t('message.items') }} {{ props.start }} {{ $t('message.to') }} {{ props.end }} {{ $t('message.of') }} {{ props.total }}
                </div>
            </template>
            <template
                slot="buttons"
                slot-scope="props"
            >
                <vue-ads-page-button
                    v-for="(button, key) in props.buttons"
                    :key="key"
                    v-bind="button"
                    @page-change="page = button.page"
                />
            </template>
        </vue-ads-pagination>

  <Group v-if="groups != null" v-for="(group, postKey) in groups" @like="userLike" :groupKey="postKey" :key="group.id" :group="group" />
   <vue-ads-pagination
            :total-items="totalGroups"
            :max-visible-pages="5"
            :page="page"
            :loading="totalGroups == 0"
            @page-change="pageChange"
            @range-change="rangeChange"
        >
            <template slot-scope="props">
                <div class="vue-ads-pr-2 vue-ads-leading-loose">
                   {{ $t('message.items') }} {{ props.start }} {{ $t('message.to') }} {{ props.end }} {{ $t('message.of') }} {{ props.total }}
                </div>
            </template>
            <template
                slot="buttons"
                slot-scope="props"
            >
                <vue-ads-page-button
                    v-for="(button, key) in props.buttons"
                    :key="key"
                    v-bind="button"
                    @page-change="page = button.page"
                />
            </template>
        </vue-ads-pagination>


  </div>
</div>
</template>

<script>
import '../../node_modules/@fortawesome/fontawesome-free/css/all.css';
import '../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';

import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';

import NewGroup from './Groups/NewGroup';
import Register from './Register';
import Group from './Groups/Group';
import { mapGetters } from 'vuex';
export default {
    name: 'NewsFeed',
    components: {
        NewGroup,
        Group,
        VueAdsPagination,
        VueAdsPageButton,
        Register
    },
    computed: {
        ...mapGetters({
            groups: 'newsGroups',
            //newsStatus: 'newsGroupsStatus',
            totalGroups: 'totalGroups',
            authUser: 'authUser'
        }),
    },
    methods: {
        userLike(data) {
            console.log("SENMT DATA:"+data);
            this.$store.dispatch('likeGroup', data);

        },
        pageChange (page) {
            console.log("FETCH"+this.page);
            this.page = page;
            this.$store.dispatch('fetchGroupsPosts', { page: this.page, that: this });
            console.log(page);
        },

        rangeChange (start, end) {
            let page = 0;
            if (start > 0) {
                page = Math.floor(start / 10);
            }
            this.$store.dispatch('fetchGroupsPosts', { page: this.page, that: this });
            this.$emit('scrollToTop');
        }
    },
    mounted() {
      console.log("GETTING POSTS2");
      this.$store.dispatch('fetchGroupsPosts', { page: this.page, that: this});
      this.$store.dispatch('getTotalGroups');
    }, data() {
      return {
        page: 0,
        loading: true,
      }
    }

}
</script>

<style>

</style>