var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bg-white" }, [
      _c("nav", { staticClass: "flex flex-col tabs sm:flex-row" }, [
        _c(
          "button",
          {
            staticClass:
              "block px-6 py-4 font-medium text-gray-600 text-blue-500 tab hover:text-blue-500 focus:outline-none",
            class:
              _vm.currentTab == "panel-1" ? "border-b-2 border-blue-500" : "",
            attrs: { "data-target": "panel-1" },
            on: { click: _vm.onTabClick },
          },
          [
            _vm._v(
              "\n                                Description\n                            "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.profileData.mapChanged == 1
          ? _c(
              "button",
              {
                staticClass:
                  "block px-6 py-4 text-gray-600 tab hover:text-blue-500 focus:outline-none",
                class:
                  _vm.currentTab == "panel-2"
                    ? "border-b-2 border-blue-500"
                    : "",
                attrs: { "data-target": "panel-2" },
                on: { click: _vm.onTabClick },
              },
              [
                _vm._v(
                  "\n                                Map\n                            "
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { id: "panels" } },
      [
        _c("ProfileDetails", {
          attrs: {
            authUser: _vm.authUser,
            currentTab: _vm.currentTab,
            profileData: _vm.profileData,
            showMoreFriends: _vm.showMoreFriends,
            tagString: _vm.tagString,
          },
        }),
        _vm._v(" "),
        _vm.currentTab == "panel-2"
          ? _c("div", { staticClass: "py-5 panel-3 tab-content" }, [
              _c(
                "div",
                { staticClass: "w-2/3 overflow-x-hidden" },
                [
                  _c(
                    "l-map",
                    {
                      ref: "map",
                      staticClass: "w-1/3 overflow-x-hidden map",
                      attrs: { center: _vm.center, zoom: _vm.zoom },
                      on: {
                        "update:zoom": _vm.zoomUpdated,
                        "update:center": _vm.centerUpdated,
                      },
                    },
                    [
                      _vm._l(_vm.markers, function (marker, index) {
                        return _c("l-marker", {
                          key: index,
                          attrs: { "lat-lng": marker },
                        })
                      }),
                      _vm._v(" "),
                      _c("l-tile-layer", { attrs: { url: _vm.url } }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }