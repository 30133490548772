<template>

 <div class="flex flex-col items-center">

  <vue-ads-pagination
            :total-items="totalAbusingUsers"
            :max-visible-pages="5"
            :page="page"
            :loading="totalAbusingUsers == 0"
            @page-change="pageChange"
            @range-change="rangeChange"
        >
            <template slot-scope="props">
                <div class="vue-ads-pr-2 vue-ads-leading-loose">
                    {{ $t('message.items') }} {{ props.start }} {{ $t('message.to') }} {{ props.end }} {{ $t('message.of') }} {{ props.total }}
                </div>
            </template>
            <template
                slot="buttons"
                slot-scope="props"
            >
                <vue-ads-page-button
                    v-for="(button, key) in props.buttons"
                    :key="key"
                    v-bind="button"
                    @page-change="page = button.page"
                />
            </template>
        </vue-ads-pagination>
  <UserAbused v-if="user != null" v-for="(user, userKey) in usersAbusingList" :key="userKey" :user="user" />
   <vue-ads-pagination
            :total-items="totalAbusingUsers"
            :max-visible-pages="5"
            :page="page"
            :loading="totalAbusingUsers == 0"
            @page-change="pageChange"
            @range-change="rangeChange"
        >
            <template slot-scope="props">
                <div class="vue-ads-pr-2 vue-ads-leading-loose">
                    {{ $t('message.items') }} {{ props.start }} {{ $t('message.to') }} {{ props.end }} {{ $t('message.of') }} {{ props.total }}
                </div>
            </template>
            <template
                slot="buttons"
                slot-scope="props"
            >
                <vue-ads-page-button
                    v-for="(button, key) in props.buttons"
                    :key="key"
                    v-bind="button"
                    @page-change="page = button.page"
                />
            </template>
        </vue-ads-pagination>
  </div>
</template>

<script>
import '../../node_modules/@fortawesome/fontawesome-free/css/all.css';
import '../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';

import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import UserAbused from './Users/UserAbused';
import { mapGetters } from 'vuex';
export default {
    name: 'NewsFeed',
    components: {
        UserAbused,
        VueAdsPagination,
        VueAdsPageButton,
    },
    computed: {
        ...mapGetters({
            usersAbusingList: 'usersAbusingList',
            totalAbusingUsers: 'totalAbusingUsers',
        }),
    },
    methods: {
        pageChange (page) {
            console.log("FETCH"+this.page);
            this.page = page;
            this.$store.dispatch('fetchUsers', this.page);
            console.log(page);
        },

        rangeChange (start, end) {
            let page = 0;
            if (start > 0) {
                page = Math.floor(start / 10);
            }
            this.$store.dispatch('fetchUsers', page);
            this.$emit('scrollToTop');
        }
    },
    mounted() {
      console.log("GETTING POSTS2");
      this.$store.dispatch('fetchAbusingUsers', this.page);
      this.$store.dispatch('getTotalAbusingUsers');
    }, data() {
      return {
        page: 0,
        loading: false,
      }
    }

}
</script>

<style>

</style>