var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col items-center" }, [
    _vm.loading
      ? _c("div", { staticClass: "w-full max-w-xs" }, [_vm._m(0)])
      : _c(
          "div",
          [
            _c("vue-ads-pagination", {
              attrs: {
                "total-items": _vm.userTotalPosts,
                "max-visible-pages": 5,
                page: _vm.page,
                loading: _vm.userTotalPosts == 0,
              },
              on: {
                "page-change": _vm.pageChange,
                "range-change": _vm.rangeChange,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (props) {
                    return [
                      _c(
                        "div",
                        { staticClass: "vue-ads-pr-2 vue-ads-leading-loose" },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("message.items")) +
                              " " +
                              _vm._s(props.start) +
                              " " +
                              _vm._s(_vm.$t("message.to")) +
                              " " +
                              _vm._s(props.end) +
                              " " +
                              _vm._s(_vm.$t("message.of")) +
                              " " +
                              _vm._s(props.total) +
                              "\n                "
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "buttons",
                  fn: function (props) {
                    return _vm._l(props.buttons, function (button, key) {
                      return _c(
                        "vue-ads-page-button",
                        _vm._b(
                          {
                            key: key,
                            on: {
                              "page-change": function ($event) {
                                _vm.page = button.page
                              },
                            },
                          },
                          "vue-ads-page-button",
                          button,
                          false
                        )
                      )
                    })
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _vm.user
              ? _c("div", [
                  _c("div", { staticClass: "relative w-full" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "z-10 w-full h-64 pb-4 overflow-hidden w-100",
                      },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/showprofile/" + _vm.user.id } },
                          [
                            _c("img", {
                              staticClass: "object-cover w-full",
                              attrs: { src: _vm.user.bannerUri },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "relative bottom-0 left-0 z-20 flex items-center -mt-12",
                    },
                    [
                      _vm.user
                        ? _c(
                            "div",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: { to: "/showprofile/" + _vm.user.id },
                                },
                                [
                                  _c("img", {
                                    staticClass:
                                      "object-cover w-32 border-4 border-gray-200 rounded-full shadow-lg",
                                    attrs: {
                                      src: _vm.user.avatarUri,
                                      alt: "user profile image",
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.user != null
                        ? _c(
                            "p",
                            { staticClass: "ml-4 text-2xl text-gray-700" },
                            [_vm._v(_vm._s(_vm.user.userName))]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.user &&
                  _vm.authUser &&
                  _vm.user.id != _vm.authUser.id &&
                  _vm.friendButtonText !== ""
                    ? _c("div", [
                        _vm.friendButtonText !== "Confirmed"
                          ? _c("div", [
                              _vm.friendButtonText !== "Accept"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "bottom-0 right-0 z-20 flex items-center mb-4 ml-12",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "px-3 py-1 bg-blue-500 rounded",
                                          on: {
                                            click: function ($event) {
                                              return _vm.$store.dispatch(
                                                "sendFriendRequest",
                                                _vm.$route.params.userId
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.friendButtonText))]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.friendButtonText === "Accept"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "bottom-0 right-0 z-20 flex items-center mb-4 ml-12",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "px-3 py-1 bg-gray-400 rounded",
                                          on: {
                                            click: function ($event) {
                                              return _vm.$store.dispatch(
                                                "acceptFriendRequest",
                                                _vm.$route.params.userId
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Accept")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.friendButtonText === "Accept"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "bottom-0 right-0 z-20 flex items-center mb-4 ml-12",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "px-3 py-1 bg-gray-400 rounded",
                                          on: {
                                            click: function ($event) {
                                              return _vm.$store.dispatch(
                                                "ignoreFriendRequest",
                                                _vm.$route.params.userId
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Ignore")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "bottom-0 right-0 z-20 flex items-center mb-4 ml-12",
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "px-3 py-1 bg-gray-400 rounded",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeFriendship()
                                        },
                                      },
                                    },
                                    [_vm._v("Friendship! (Remove friendship)")]
                                  ),
                                ]
                              ),
                            ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", [
                    _vm.authUser && _vm.user.id != _vm.authUser.id
                      ? _c(
                          "button",
                          {
                            staticClass: "px-3 py-1 bg-blue-500 rounded",
                            on: {
                              click: function ($event) {
                                return _vm.blockUser(_vm.$route.params.userId)
                              },
                            },
                          },
                          [_vm._v("Remove/block user")]
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.posts, function (post, postKey) {
              return _c("Post", {
                key: post.id,
                attrs: { postKey: postKey, post: post },
                on: { like: _vm.userLike },
              })
            }),
            _vm._v(" "),
            _c("vue-ads-pagination", {
              attrs: {
                "total-items": _vm.userTotalPosts,
                "max-visible-pages": 5,
                page: _vm.page,
                loading: _vm.userTotalPosts == 0,
              },
              on: {
                "page-change": _vm.pageChange,
                "range-change": _vm.rangeChange,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (props) {
                    return [
                      _c(
                        "div",
                        { staticClass: "vue-ads-pr-2 vue-ads-leading-loose" },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("message.items")) +
                              " " +
                              _vm._s(props.start) +
                              " " +
                              _vm._s(_vm.$t("message.to")) +
                              " " +
                              _vm._s(props.end) +
                              " " +
                              _vm._s(_vm.$t("message.of")) +
                              " " +
                              _vm._s(props.total) +
                              "\n                "
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "buttons",
                  fn: function (props) {
                    return _vm._l(props.buttons, function (button, key) {
                      return _c(
                        "vue-ads-page-button",
                        _vm._b(
                          {
                            key: key,
                            on: {
                              "page-change": function ($event) {
                                _vm.page = button.page
                              },
                            },
                          },
                          "vue-ads-page-button",
                          button,
                          false
                        )
                      )
                    })
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _vm.posts == null || _vm.posts.length == 0
              ? _c("p", [
                  _vm._v(_vm._s(_vm.$t("message.nopostsfoundgetstarted"))),
                ])
              : _vm._e(),
          ],
          2
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex items-center justify-center " }, [
      _c("div", {
        staticClass:
          "w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }