var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-6 overflow-hidden bg-white rounded shadow" },
    [
      _c("div", { staticClass: "flex flex-col p-4" }, [
        _vm.group.postCount != 0
          ? _c(
              "div",
              [
                _c(
                  "router-link",
                  { attrs: { to: "/groupdetailsfeed/" + _vm.group.id } },
                  [
                    _c("div", { staticClass: "mt-4 text-blue-700" }, [
                      _vm._v(_vm._s(_vm.group.name)),
                    ]),
                  ]
                ),
              ],
              1
            )
          : _c("div", [
              _vm.authUser != null
                ? _c(
                    "div",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/groupdetailsfeed/" + _vm.group.id } },
                        [
                          _c("div", { staticClass: "mt-4" }, [
                            _vm._v(_vm._s(_vm.group.name)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _c("div", [
                    _c("div", { staticClass: "mt-4" }, [
                      _vm._v(_vm._s(_vm.group.name)),
                    ]),
                  ]),
            ]),
      ]),
      _vm._v(" "),
      _vm.group.photo != null
        ? _c("div", { staticClass: "w-full" }, [
            _vm.group.photoType == "mp4"
              ? _c(
                  "div",
                  [
                    _c("vue-plyr", [
                      _c(
                        "video",
                        {
                          attrs: {
                            controls: "",
                            crossorigin: "",
                            playsinline: "",
                          },
                        },
                        [
                          _c("source", {
                            attrs: { src: _vm.group.photo, type: "video/mp4" },
                          }),
                        ]
                      ),
                    ]),
                  ],
                  1
                )
              : _c("div", [
                  _c("img", {
                    staticClass: "w-full",
                    attrs: { src: _vm.group.photo },
                  }),
                ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-between m-4 border-gray-400 border-1" },
        [
          _c(
            "button",
            {
              staticClass:
                "flex justify-center w-full p-4 rounded hover:bg-gray-100 ",
              class:
                _vm.group.logged_in_user_likes_group == 1
                  ? "bg-blue-600 text-white hover:text-black"
                  : "",
              on: { click: _vm.likeGroup },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "w-5 h-5 fill-current",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 24 24",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M20.8 15.6c.4-.5.6-1.1.6-1.7 0-.6-.3-1.1-.5-1.4.3-.7.4-1.7-.5-2.6-.7-.6-1.8-.9-3.4-.8-1.1.1-2 .3-2.1.3-.2 0-.4.1-.7.1 0-.3 0-.9.5-2.4.6-1.8.6-3.1-.1-4.1-.7-1-1.8-1-2.1-1-.3 0-.6.1-.8.4-.5.5-.4 1.5-.4 2-.4 1.5-2 5.1-3.3 6.1l-.1.1c-.4.4-.6.8-.8 1.2-.2-.1-.5-.2-.8-.2H3.7c-1 0-1.7.8-1.7 1.7v6.8c0 1 .8 1.7 1.7 1.7h2.5c.4 0 .7-.1 1-.3l1 .1c.2 0 2.8.4 5.6.3.5 0 1 .1 1.4.1.7 0 1.4-.1 1.9-.2 1.3-.3 2.2-.8 2.6-1.6.3-.6.3-1.2.3-1.6.8-.8 1-1.6.9-2.2.1-.3 0-.6-.1-.8zM3.7 20.7c-.3 0-.6-.3-.6-.6v-6.8c0-.3.3-.6.6-.6h2.5c.3 0 .6.3.6.6v6.8c0 .3-.3.6-.6.6H3.7zm16.1-5.6c-.2.2-.2.5-.1.7 0 0 .2.3.2.7 0 .5-.2 1-.8 1.4-.2.2-.3.4-.2.6 0 0 .2.6-.1 1.1-.3.5-.9.9-1.8 1.1-.8.2-1.8.2-3 .1h-.1c-2.7.1-5.4-.3-5.4-.3H8v-7.2c0-.2 0-.4-.1-.5.1-.3.3-.9.8-1.4 1.9-1.5 3.7-6.5 3.8-6.7v-.3c-.1-.5 0-1 .1-1.2.2 0 .8.1 1.2.6.4.6.4 1.6-.1 3-.7 2.1-.7 3.2-.2 3.7.3.2.6.3.9.2.3-.1.5-.1.7-.1h.1c1.3-.3 3.6-.5 4.4.3.7.6.2 1.4.1 1.5-.2.2-.1.5.1.7 0 0 .4.4.5 1 0 .3-.2.6-.5 1z",
                    },
                  }),
                ]
              ),
              _c("p", { staticClass: "ml-2" }, [_vm._v("Like")]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-between px-4 text-sm text-gray-700" },
        [
          _c("div", [
            _vm.group.likesLength != 1
              ? _c("p", [_vm._v(_vm._s(_vm.group.likesLength) + " likes")])
              : _c("p", [_vm._v("1 like")]),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm.group.postCount != 1
              ? _c("p", [_vm._v(_vm._s(_vm.group.postCount) + " posts")])
              : _c("p", [_vm._v("1 post")]),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm.group.postCount != 1
              ? _c("p", [_vm._v(_vm._s(_vm.group.membersCount) + " members")])
              : _c("p", [_vm._v("1 member")]),
          ]),
          _vm._v(" "),
          _vm.admin && _vm.authUser
            ? _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.deleteGroup(_vm.group.id)
                    },
                  },
                },
                [_c("p", [_vm._v("DELETE")])]
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }