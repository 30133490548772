<template>
 <div class="mt-6 overflow-hidden bg-white rounded shadow">
        <div class="flex flex-col p-4">
            <div v-if="group.postCount != 0">
            <router-link :to="'/groupdetailsfeed/'+group.id">
                <div class="mt-4 text-blue-700">{{ group.name }}</div>
            </router-link>
            </div>
            <div v-else>
                <div v-if="authUser!=null">
                    <router-link :to="'/groupdetailsfeed/'+group.id">
                        <div class="mt-4">{{ group.name }}</div>
                    </router-link>
                </div>
                <div v-else>
                    <div class="mt-4">{{ group.name }}</div>
                </div>
            </div>
        </div>


            <div class="w-full" v-if="group.photo!=null">
                <div v-if="group.photoType=='mp4'">
                    <vue-plyr>
                        <video
                            controls
                            crossorigin
                            playsinline
                        >
                            <source
                            :src="group.photo"
                            type="video/mp4"
                            />
                        </video>
                        </vue-plyr>
                </div>
                <div v-else>
                    <img :src="group.photo" class="w-full">
                </div>
            </div>
            <div class="flex justify-between m-4 border-gray-400 border-1">
                    <button @click="likeGroup" class="flex justify-center w-full p-4 rounded hover:bg-gray-100 " :class="group.logged_in_user_likes_group == 1 ? `bg-blue-600 text-white hover:text-black` : ``">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5 fill-current">
                        <path d="M20.8 15.6c.4-.5.6-1.1.6-1.7 0-.6-.3-1.1-.5-1.4.3-.7.4-1.7-.5-2.6-.7-.6-1.8-.9-3.4-.8-1.1.1-2 .3-2.1.3-.2 0-.4.1-.7.1 0-.3 0-.9.5-2.4.6-1.8.6-3.1-.1-4.1-.7-1-1.8-1-2.1-1-.3 0-.6.1-.8.4-.5.5-.4 1.5-.4 2-.4 1.5-2 5.1-3.3 6.1l-.1.1c-.4.4-.6.8-.8 1.2-.2-.1-.5-.2-.8-.2H3.7c-1 0-1.7.8-1.7 1.7v6.8c0 1 .8 1.7 1.7 1.7h2.5c.4 0 .7-.1 1-.3l1 .1c.2 0 2.8.4 5.6.3.5 0 1 .1 1.4.1.7 0 1.4-.1 1.9-.2 1.3-.3 2.2-.8 2.6-1.6.3-.6.3-1.2.3-1.6.8-.8 1-1.6.9-2.2.1-.3 0-.6-.1-.8zM3.7 20.7c-.3 0-.6-.3-.6-.6v-6.8c0-.3.3-.6.6-.6h2.5c.3 0 .6.3.6.6v6.8c0 .3-.3.6-.6.6H3.7zm16.1-5.6c-.2.2-.2.5-.1.7 0 0 .2.3.2.7 0 .5-.2 1-.8 1.4-.2.2-.3.4-.2.6 0 0 .2.6-.1 1.1-.3.5-.9.9-1.8 1.1-.8.2-1.8.2-3 .1h-.1c-2.7.1-5.4-.3-5.4-.3H8v-7.2c0-.2 0-.4-.1-.5.1-.3.3-.9.8-1.4 1.9-1.5 3.7-6.5 3.8-6.7v-.3c-.1-.5 0-1 .1-1.2.2 0 .8.1 1.2.6.4.6.4 1.6-.1 3-.7 2.1-.7 3.2-.2 3.7.3.2.6.3.9.2.3-.1.5-.1.7-.1h.1c1.3-.3 3.6-.5 4.4.3.7.6.2 1.4.1 1.5-.2.2-.1.5.1.7 0 0 .4.4.5 1 0 .3-.2.6-.5 1z"/>
                        </svg><p class="ml-2">Like</p>
                    </button>
            </div>

            <div class="flex justify-between px-4 text-sm text-gray-700">
                   <div>
                        <p v-if="group.likesLength != 1">{{ group.likesLength }} likes</p>
                        <p v-else>1 like</p>
                    </div>
                    <div>
                        <p v-if="group.postCount != 1">{{ group.postCount }} posts</p>
                        <p v-else>1 post</p>
                    </div>
                    <div>
                        <p v-if="group.postCount != 1">{{ group.membersCount }} members</p>
                        <p v-else>1 member</p>
                    </div>
                    <div @click="deleteGroup(group.id)" v-if="admin && authUser">
                        <p>DELETE</p>
                    </div>
            </div>
        </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from 'vuex';
export default {
    name: "Group",
    props: ["group", "groupKey"],
    methods: {
        likeGroup() {
            this.$emit('like', { postId: this.group.id, groupKey: this.groupKey });
        },
        deleteGroup(id) {
             if (confirm('Are you sure?')) {
                axios.post('/api/deletegroup', { 'group_id' : id })
                            .then(res => {
                                console.log("Deleted group");
                                this.$store.dispatch('removeGroup', id);
                            }).catch(error => {
                                console.error("An error occured deleting the posts");
                            });
             }
        },
    },
        data : () => {
        return {
            comments : null,
            commentBody : ''
        }
    }, computed: {
        ...mapGetters({
            admin: 'admin',
            authUser: 'authUser',
        }),

    }

}
</script>

<style>

</style>