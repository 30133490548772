<template>
    <Home></Home>
</template>
<script>
import Home from './components/Home';
export default {
    name: "App",
    mounted() {
            console.log("MOUNTED");
            this.$store.dispatch('fetchAuthUser');
            console.log("FETCHCONFIG");
            this.$store.dispatch('fetchConfig', this);

    },
    created() {
        this.$store.dispatch('setPageTitle', this.$route.meta.title);
    },
    watch: {
        $route(to, from) {
            this.$store.dispatch('setPageTitle', to.meta.title);
        }
    },
    components:  { Home }
}
</script>
<style scoped>

</style>