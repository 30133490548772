var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col items-center" },
    [
      _c("vue-ads-pagination", {
        attrs: {
          "total-items": _vm.totalJoinRequests,
          "max-visible-pages": 5,
          page: _vm.page,
          loading: _vm.totalJoinRequests == 0,
        },
        on: { "page-change": _vm.pageChange, "range-change": _vm.rangeChange },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return [
                _c(
                  "div",
                  { staticClass: "vue-ads-pr-2 vue-ads-leading-loose" },
                  [
                    _vm._v(
                      "\n                   " +
                        _vm._s(_vm.$t("message.items")) +
                        " " +
                        _vm._s(props.start) +
                        " " +
                        _vm._s(_vm.$t("message.to")) +
                        " " +
                        _vm._s(props.end) +
                        " " +
                        _vm._s(_vm.$t("message.of")) +
                        " " +
                        _vm._s(props.total) +
                        "\n               "
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "buttons",
            fn: function (props) {
              return _vm._l(props.buttons, function (button, key) {
                return _c(
                  "vue-ads-page-button",
                  _vm._b(
                    {
                      key: key,
                      on: {
                        "page-change": function ($event) {
                          _vm.page = button.page
                        },
                      },
                    },
                    "vue-ads-page-button",
                    button,
                    false
                  )
                )
              })
            },
          },
        ]),
      }),
      _vm._v(" "),
      _vm.joinRequestsList.length > 0
        ? _c(
            "div",
            _vm._l(
              _vm.joinRequestsList,
              function (joinRequest, joinRequestKey) {
                return _c("JoinRequest", {
                  key: joinRequestKey,
                  attrs: { page: _vm.page, joinRequest: joinRequest },
                })
              }
            ),
            1
          )
        : _c("div", [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("message.nojoinrequests")) + "\n   "
            ),
          ]),
      _vm._v(" "),
      _c(
        "div",
        { on: { click: _vm.clicked } },
        [
          _c("vue-ads-pagination", {
            attrs: {
              "total-items": _vm.totalJoinRequests,
              "max-visible-pages": 5,
              page: _vm.page,
              loading: _vm.totalJoinRequests == 0,
            },
            on: {
              "page-change": _vm.pageChange,
              "range-change": _vm.rangeChange,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "div",
                      { staticClass: "vue-ads-pr-2 vue-ads-leading-loose" },
                      [
                        _vm._v(
                          "\n                   " +
                            _vm._s(_vm.$t("message.items")) +
                            " " +
                            _vm._s(props.start) +
                            " " +
                            _vm._s(_vm.$t("message.to")) +
                            " " +
                            _vm._s(props.end) +
                            " " +
                            _vm._s(_vm.$t("message.of")) +
                            " " +
                            _vm._s(props.total) +
                            "\n               "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "buttons",
                fn: function (props) {
                  return _vm._l(props.buttons, function (button, key) {
                    return _c(
                      "vue-ads-page-button",
                      _vm._b(
                        {
                          key: key,
                          on: {
                            "page-change": function ($event) {
                              _vm.page = button.page
                            },
                          },
                        },
                        "vue-ads-page-button",
                        button,
                        false
                      )
                    )
                  })
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }