<template>
    <div class="py-5 panel-1 tab-content" v-if="currentTab=='panel-1'">

        <div class="content-center p-10 mx-5 max-w-f6xl xl:xm-auto">
            <div class="grid content-center grid-cols-3 gap-3">

                <div class="avatar">
                    <router-link :to="'/'+profileData.userName">
                    <img class="rounded-full w-36 h-36" :src="profileData.avatarImage" />
                    </router-link>
                </div>
                <div class="col-span-2">
                    <span class="mr-4 text-2xl text-gray-700">{{ profileData.firstName }} {{ profileData.lastName }}</span>
                    <div class="flex mt-4">
                        <span class="mr-4 text-gray-700 text-1xl">{{ profileData.url }}</span>
                    </div>
                    <div class="flex mt-4">
                        <div><span class="font-semibold">{{ profileData.totalPosts }} </span> posts</div>
                        <div class="ml-4"><span class="font-semibold">{{ profileData.totalFollowers }}</span> followers</div>
                        <div class="ml-4"><span class="font-semibold">{{ profileData.totalFollowing }}</span> following</div>
                    </div>
                    <div class="pt-3">
                        <span class="text-lg font-semibold text-gray-70">{{ profileData.companyName }}</span>
                    </div>
                    <div class="pt-3">
                        <p class="mr-2 text-base text-blue-700">{{ tagString() }}</p>
                        <p class="pt-3 mr-2 text-base font-medium text-blue-700">{{ profileData.website }}</p>
                    </div>
                </div>
            </div>
            <div class="container pt-4 mx-auto">
                            <div class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6">
                                <div v-for="(story, index) in profileData.friends" :key="index" class="flex justify-center p-1 text-6xl bg-gray-100 border-2 border-gray-300 rounded-xl">
                                    <ProfileStory :img="story.avatarImage" :username="story.firstName+' '+story.lastName" /></div>
                                <div v-if="showMoreFriends" class="flex justify-center p-1 pt-6 text-2xl text-center bg-gray-100 border-2 rounded-xl">...more friends</div>
                            </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProfileStory from './ProfileStory';
export default {
  name: "ProfileDetails",
  props: ["authUser", "profileData", "currentTab", "tagString", "showMoreFriends"],
  components: { ProfileStory
  }
}
</script>

<style>

</style>