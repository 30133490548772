var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col flex-1 h-screen" },
    [
      _c("Nav"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex overflow-y-hidden flex-1" },
        [
          _c("Sidebar"),
          _vm._v(" "),
          _c(
            "div",
            { ref: "myref", staticClass: "overflow-x-hidden w-2/3" },
            [
              _c("router-view", {
                key: _vm.$route.fullPath,
                on: { scrollToTop: _vm.scrollToTop },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }