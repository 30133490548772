<template>
    <div class="flex flex-col flex-1 h-screen">
       <Nav />
       <div class="flex overflow-y-hidden flex-1"  >
           <Sidebar />
        <div class="overflow-x-hidden w-2/3" ref="myref">

             <router-view @scrollToTop="scrollToTop" :key="$route.fullPath"></router-view>

       </div>
       </div>
    </div>
</template>
<script>
import Nav from './Nav';
import Sidebar from './Sidebar';

export default {

    name: 'Home',
    components: {
        Nav,
        Sidebar,
    }, methods: {
        scrollToTop() {
            if (this.$refs.myref) this.$refs.myref.scrollTop = 0;
        }
    }
}
</script>
<style scoped>
</style>