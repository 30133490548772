<template>
    <div class="w-1/3 p-4 bg-white">
    <router-link to="/" v-if="authUser" class="px-6">
        <h2 class="text-2xl font-bold tracking-tight border-gray-400">News feed</h2>
    </router-link>
    <router-link to="/profile" v-if="authUser" class="px-6">
        <h2 class="text-2xl font-bold tracking-tight border-gray-400"> {{ $t('message.profile') }}</h2>
    </router-link>
    <router-link to="/userlist" class="px-6">
        <h2 class="text-2xl font-bold tracking-tight border-gray-400"> {{ $t('message.userlist') }}</h2>
    </router-link>
     <router-link to="/friendrequests" v-if="authUser" class="px-6">
        <h2 class="text-2xl font-bold tracking-tight border-gray-400"> {{ $t('message.friendrequests') }}</h2>
    </router-link>
     <router-link to="/register" v-if="!authUser" class="px-6">
        <h2 class="text-2xl font-bold tracking-tight border-gray-400"> {{ $t('message.register') }}</h2>
    </router-link>
    <router-link to="/groups" class="px-6">
        <h2 class="text-2xl font-bold tracking-tight border-gray-400"> {{ $t('message.groups') }}</h2>
    </router-link>
     <router-link to="/legalnotice" v-if="!authUser" class="px-6">
        <h2 class="text-2xl font-bold tracking-tight border-gray-400"> {{ $t('message.legalnotice') }}</h2>
    </router-link>
    </div>
</template>

<script>
 import { mapGetters } from 'vuex';
export default {
    name: "Sidebar",
    computed: {
            ...mapGetters({
                    authUser: 'authUser',
            }),
    }
}
</script>

<style>

</style>