import axios from "axios"

const state = {
    newsGroups: null,
    newsGroupsStatus : null,
    postGroup : '',
    groupUploadedFilename : '',
    totalGroups : 0,
}

const getters = {
  newsGroups  : state => {
      return state.newsGroups
  },
  newsGroupsStatus: state => {
    return {
      newsGroupsStatus : state.newsGroupsStatus,
    }
  },
  postGroup: state => {
    return state.postGroup;
  },
  totalGroups: state => {
    return state.totalGroups
  }
}


const actions = {
  fetchGroupsPosts({ commit, state }, data) {
    commit('setGroupsStatus', 'loading');
    console.error("DATA.PAGE2:"+data.page);
    axios.get('/api/groups/'+data.page).then(resp => {
      commit('setNewsGroups', resp.data);
      commit('setGroupsStatus', 'success');
    data.that.loading = false;
  }).catch(error => {
    commit('setGroupsStatus', 'error');
   data.that.loading = false;
  });
},
getTotalGroups({commit, state}) {
  axios.get('/api/totalgroups').then(resp => {
    console.log("GOT TOTALPOSTS");
    console.log(resp.data);
    console.log(resp.data.totalPosts);
    commit('updateTotalGroups', resp.data.totalGroups);
    }).catch(error => {
    });
},
postGroup({commit, state}, that) {
  commit('setGroupsStatus', 'loading');
  axios.post('/api/postgroup', { body: state.postGroup, uploadedFilename : state.groupUploadedFilename })
  .then(res => {
    that.loading = false;
    if (res.data.status=='error') {
      alert(res.data.details);
    } else {
    commit('pushGroup', res.data);
    commit('updateMessage', '')
    }

  }).catch(error => {
    that.loading = false;
    commit('setGroupsStatus', 'error');
  })
},
likeGroup({commit, state}, data) {
  axios.post('/api/groups/like/'+data.postId)
  .then(res => {
    commit('pushGroupLikes', { likes: res.data, groupKey: data.groupKey });
    commit('updateMessage', '');
  }).catch(error => {
    commit('setGroupsStatus', 'error');
  })
},
removeGroup({commit, state}, id) {
   commit('removeGroup', id);
}

}

  const mutations = {
    setNewsGroups(state, groups) {
      state.newsGroups = groups;
    },
    setGroupsStatus(state, status) {
      state.newsGroupsStatus = status;
    },
    updateMessage(state, message) {
      console.log("Setting postMessagge to "+message+"*");
      state.postGroup = message;
    },
    updateGroupUploadedFilename(state, filename) {
      state.groupUploadedFilename = filename;
    },
    updateTotalGroups(state, totalGroups) {
      state.totalGroups = totalGroups;
      console.log("TOTALGROUPS SET TO "+totalGroups);
    },
    pushGroup(state, group) {
      console.log("PUSHING GROUP:");
      console.error(group);
      state.newsGroups.splice(0, 0, group);
    },
    pushGroupLikes(state, data) {
      console.log("PUSHLIKES");
      console.log("DATA.LIKES:"+data.likes);
      console.log("DATA.postkey:"+data.groupKey);
      console.log("DATA.likesLenght:");
      console.log(state.newsGroups);
      console.log(state.newsGroups[data.groupKey]);
      console.log(state.newsGroups[data.groupKey].likesLength);
      state.newsGroups[data.groupKey].likesLength=data.likes.likesLength;
    },
    removeGroup(state, id) {
      console.log("REMOVEGROUP CALLED");
      let delIndex = -1;
      for (var i = 0; i < state.newsGroups.length; i++) {
        console.log(state.newsGroups[i].id+"=="+id);
          if (state.newsGroups[i].id==id) {
              delIndex = i;
          }
      }
      if (delIndex != -1) {
        console.log("DELINDEX:"+delIndex)
      state.newsGroups.splice(delIndex, 1);
      }
    }
  }

  export default {
    state, getters, actions, mutations
  }