import axios from "axios"

const state = {
    friendRequestsList : [],
    totalFriendRequests : 0
}

const getters = {
  friendRequestsList : state => {
      return state.friendRequestsList
  },
  totalFriendRequests: state => {
    return state.totalFriendRequests
  }
}


const actions = {
  fetchFriendRequests({ commit, state }, page) {
    axios.get('/api/friend_requests/'+page).then(resp => {
      console.log("GOT FRIEND REQUESTS");
      console.log(resp.data);
      commit('setFriendRequestsList', resp.data);
  }).catch(error => {
    console.log("Error loading users");
});
},
getTotalFriendRequests({commit, state}, page) {
  axios.get('/api/totalfriendrequests').then(resp => {
    console.log("GOT TOTAL FRIEND REQUEST");
    console.log(resp.data);
    console.log(resp.data.totalFriendRequests);
    commit('updateTotalFriendRequests', resp.data.totalFriendRequests);
    }).catch(error => {
    });
}

}

  const mutations = {
    setFriendRequestsList(state, friendRequests) {
      state.friendRequestsList = friendRequests;
    },
    updateTotalFriendRequests(state, total) {
      state.totalFriendRequests = total;
    },
  }

  export default {
    state, getters, actions, mutations
  }