var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p-4 bg-white rounded shadow " }, [
    _c("div", { staticClass: "mx-4 mb-4" }, [
      _vm._v(
        "\n                  " +
          _vm._s(_vm.$t("message.currentimage")) +
          "\n                "
      ),
    ]),
    _vm._v(" "),
    _c("div", [
      _vm.photoType == "mp4"
        ? _c(
            "div",
            [
              _c("vue-plyr", [
                _c(
                  "video",
                  { attrs: { controls: "", crossorigin: "", playsinline: "" } },
                  [
                    _c("source", {
                      attrs: { src: _vm.photo, type: "video/mp4" },
                    }),
                  ]
                ),
              ]),
            ],
            1
          )
        : _c("div", [
            _c("img", { staticClass: "w-full", attrs: { src: _vm.photo } }),
          ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mx-4 mt-4" }, [
      _vm._v(
        "\n                   " +
          _vm._s(_vm.$t("message.newimage")) +
          "\n                "
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "m-4" },
      [
        _c("vue-dropzone", {
          ref: "myVueDropzone",
          attrs: {
            awss3: _vm.awss3,
            id: "dropzone",
            options: _vm.dropzoneOptions,
          },
          on: {
            "vdropzone-upload-progress": _vm.sending,
            "vdropzone-s3-upload-error": _vm.s3UploadError,
            "vdropzone-s3-upload-success": _vm.s3UploadSuccess,
            "vdropzone-success": _vm.added,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", [
      _vm._v(
        "\n            " +
          _vm._s(_vm.$t("message.totalkb")) +
          ": " +
          _vm._s(_vm.totalKB) +
          " Kilobytes\n        "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }