var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-4 bg-white rounded shadow w-100" }, [
    _c("div", { staticClass: "p-4" }, [
      _c("div", { staticClass: "flex flex-col" }, [
        _c("img", {
          staticClass: "w-8 h-8 rounded-full",
          attrs: { src: _vm.groupMember.avatarUri },
        }),
        _vm._v(
          _vm._s(_vm.groupMember.firstName) +
            " " +
            _vm._s(_vm.groupMember.lastName) +
            " " +
            _vm._s(_vm.groupMember.id) +
            "\n                   "
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm.groupDetails.isOwner == 1
      ? _c("div", { staticClass: "flex" }, [
          _c("input", {
            staticClass:
              "px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline",
            attrs: { type: "button", value: _vm.$t("message.remove") },
            on: {
              click: function ($event) {
                return _vm.deleteUserFromGroup(_vm.groupMember.id)
              },
            },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }