var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-1/3 p-4 bg-white" },
    [
      _vm.authUser
        ? _c("router-link", { staticClass: "px-6", attrs: { to: "/" } }, [
            _c(
              "h2",
              {
                staticClass:
                  "text-2xl font-bold tracking-tight border-gray-400",
              },
              [_vm._v("News feed")]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.authUser
        ? _c(
            "router-link",
            { staticClass: "px-6", attrs: { to: "/profile" } },
            [
              _c(
                "h2",
                {
                  staticClass:
                    "text-2xl font-bold tracking-tight border-gray-400",
                },
                [_vm._v(" " + _vm._s(_vm.$t("message.profile")))]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("router-link", { staticClass: "px-6", attrs: { to: "/userlist" } }, [
        _c(
          "h2",
          { staticClass: "text-2xl font-bold tracking-tight border-gray-400" },
          [_vm._v(" " + _vm._s(_vm.$t("message.userlist")))]
        ),
      ]),
      _vm._v(" "),
      _vm.authUser
        ? _c(
            "router-link",
            { staticClass: "px-6", attrs: { to: "/friendrequests" } },
            [
              _c(
                "h2",
                {
                  staticClass:
                    "text-2xl font-bold tracking-tight border-gray-400",
                },
                [_vm._v(" " + _vm._s(_vm.$t("message.friendrequests")))]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.authUser
        ? _c(
            "router-link",
            { staticClass: "px-6", attrs: { to: "/register" } },
            [
              _c(
                "h2",
                {
                  staticClass:
                    "text-2xl font-bold tracking-tight border-gray-400",
                },
                [_vm._v(" " + _vm._s(_vm.$t("message.register")))]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("router-link", { staticClass: "px-6", attrs: { to: "/groups" } }, [
        _c(
          "h2",
          { staticClass: "text-2xl font-bold tracking-tight border-gray-400" },
          [_vm._v(" " + _vm._s(_vm.$t("message.groups")))]
        ),
      ]),
      _vm._v(" "),
      !_vm.authUser
        ? _c(
            "router-link",
            { staticClass: "px-6", attrs: { to: "/legalnotice" } },
            [
              _c(
                "h2",
                {
                  staticClass:
                    "text-2xl font-bold tracking-tight border-gray-400",
                },
                [_vm._v(" " + _vm._s(_vm.$t("message.legalnotice")))]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }