import axios from "axios"

const state = {
    usersList : [],
    totalUsers: 0
}

const getters = {
  usersList : state => {
      return state.usersList
  },
  totalUsers: state => {
    return state.totalUsers
  }
}


const actions = {
  fetchUsers({ commit, state }, currentPage) {
    commit('setPostsStatus', 'loading');
    axios.get('/api/users/'+currentPage).then(resp => {
      console.log("GOT POSTS");
      console.log(resp.data);
      commit('setUsersList', resp.data);
  }).catch(error => {
    console.log("Error loading users");
});
},
getTotalUsers({commit, state}, page) {
  axios.get('/api/totalusers').then(resp => {
    console.log("GOT TOTALUSERS");
    console.log(resp.data);
    console.log(resp.data.totalUsers);
    commit('updateTotalUsers', resp.data.totalUsers);
    }).catch(error => {
    });
}

}

  const mutations = {
    setUsersList(state, users) {
      state.usersList = users;
    },
    updateTotalUsers(state, total) {
      state.totalUsers = total;
    },
  }

  export default {
    state, getters, actions, mutations
  }