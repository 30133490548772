<template>

 <div class="flex flex-col items-center">

  <vue-ads-pagination
            :total-items="totalJoinRequests"
            :max-visible-pages="5"
            :page="page"
            :loading="totalJoinRequests == 0"
            @page-change="pageChange"
            @range-change="rangeChange"
        >
            <template slot-scope="props">
                <div class="vue-ads-pr-2 vue-ads-leading-loose">
                    {{ $t('message.items') }} {{ props.start }} {{ $t('message.to') }} {{ props.end }} {{ $t('message.of') }} {{ props.total }}
                </div>
            </template>
            <template
                slot="buttons"
                slot-scope="props"
            >
                <vue-ads-page-button
                    v-for="(button, key) in props.buttons"
                    :key="key"
                    v-bind="button"
                    @page-change="page = button.page"
                />
            </template>
        </vue-ads-pagination>
        <div v-if="joinRequestsList.length > 0">
        <JoinRequest :page="page" v-for="(joinRequest, joinRequestKey) in joinRequestsList" :key="joinRequestKey" :joinRequest="joinRequest" />
   </div>
   <div v-else>
       {{ $t('message.nojoinrequests') }}
    </div>
    <div @click="clicked">
    <vue-ads-pagination
            :total-items="totalJoinRequests"
            :max-visible-pages="5"
            :page="page"
            :loading="totalJoinRequests == 0"
            @page-change="pageChange"
            @range-change="rangeChange"
        >
            <template slot-scope="props">
                <div class="vue-ads-pr-2 vue-ads-leading-loose">
                    {{ $t('message.items') }} {{ props.start }} {{ $t('message.to') }} {{ props.end }} {{ $t('message.of') }} {{ props.total }}
                </div>
            </template>
            <template
                slot="buttons"
                slot-scope="props"
            >
                <vue-ads-page-button
                    v-for="(button, key) in props.buttons"
                    :key="key"
                    v-bind="button"
                    @page-change="page = button.page"
                />
            </template>
        </vue-ads-pagination>
        </div>
  </div>
</template>

<script>
import '../../node_modules/@fortawesome/fontawesome-free/css/all.css';
import '../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';

import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import JoinRequest from './JoinRequest/JoinRequest';
import { mapGetters } from 'vuex';
export default {
    name: 'JoinRequests',
    components: {
        JoinRequest,
        VueAdsPagination,
        VueAdsPageButton,
    },
    computed: {
        ...mapGetters({
            joinRequestsList: 'joinRequestsList',
            totalJoinRequests: 'totalJoinRequests',
        }),
    },
    methods: {
        clicked() {
            console.error("CLICKED");
        this.$emit('scrollToTop');
        },
        pageChange (page) {
        },
        rangeChange (start, end) {
            let page = 0;
            if (start > 0) {
                page = Math.floor(start / 10);
            }
            this.$store.dispatch('fetchJoinRequests', this.page);

            this.doScroll = true;
        }
    },
    mounted() {
      console.log("GETTING POSTS2");
      this.$store.dispatch('fetchJoinRequests', this.page);
      this.$store.dispatch('getTotalJoinRequests');
    }, data() {
      return {
        page: 0,
        doScroll : true,
        loading: false,
      }
    }

}
</script>

<style>

</style>