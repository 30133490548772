var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-6 overflow-hidden bg-white rounded shadow" },
    [
      _c(
        "div",
        { staticClass: "flex flex-col p-4" },
        [
          _c("div", { staticClass: "flex items-center" }, [
            _c(
              "div",
              { staticClass: "w-8" },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/" + _vm.post.user.userName } },
                  [
                    _c("img", {
                      staticClass: "object-cover w-8 h-8 rounded-full",
                      attrs: { src: _vm.post.user.avatarUri },
                    }),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-6" }, [
              _c("div", [_vm._v(_vm._s(_vm.post.user.displayName))]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.post.humanCreatedAt) + " ")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-4" }, [_vm._v(_vm._s(_vm.post.body))]),
          _vm._v(" "),
          _vm.post.photo != null
            ? _c("div", { staticClass: "w-full" }, [
                _vm.post.photoType == "mp4"
                  ? _c(
                      "div",
                      [
                        _c("vue-plyr", [
                          _c(
                            "video",
                            {
                              attrs: {
                                controls: "",
                                crossorigin: "",
                                playsinline: "",
                              },
                            },
                            [
                              _c("source", {
                                attrs: {
                                  src: _vm.post.photo,
                                  type: "video/mp4",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ],
                      1
                    )
                  : _c("div", [
                      _c("img", {
                        staticClass: "w-full",
                        attrs: { src: _vm.post.photo },
                      }),
                    ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex justify-between m-4 border-gray-400 border-1",
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "flex justify-center w-full p-4 rounded hover:bg-gray-100 ",
                  class:
                    _vm.post.logged_in_user_likes_post == 1
                      ? "bg-blue-600 text-white hover:text-black"
                      : "",
                  on: { click: _vm.likePost },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "w-5 h-5 fill-current",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 24 24",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M20.8 15.6c.4-.5.6-1.1.6-1.7 0-.6-.3-1.1-.5-1.4.3-.7.4-1.7-.5-2.6-.7-.6-1.8-.9-3.4-.8-1.1.1-2 .3-2.1.3-.2 0-.4.1-.7.1 0-.3 0-.9.5-2.4.6-1.8.6-3.1-.1-4.1-.7-1-1.8-1-2.1-1-.3 0-.6.1-.8.4-.5.5-.4 1.5-.4 2-.4 1.5-2 5.1-3.3 6.1l-.1.1c-.4.4-.6.8-.8 1.2-.2-.1-.5-.2-.8-.2H3.7c-1 0-1.7.8-1.7 1.7v6.8c0 1 .8 1.7 1.7 1.7h2.5c.4 0 .7-.1 1-.3l1 .1c.2 0 2.8.4 5.6.3.5 0 1 .1 1.4.1.7 0 1.4-.1 1.9-.2 1.3-.3 2.2-.8 2.6-1.6.3-.6.3-1.2.3-1.6.8-.8 1-1.6.9-2.2.1-.3 0-.6-.1-.8zM3.7 20.7c-.3 0-.6-.3-.6-.6v-6.8c0-.3.3-.6.6-.6h2.5c.3 0 .6.3.6.6v6.8c0 .3-.3.6-.6.6H3.7zm16.1-5.6c-.2.2-.2.5-.1.7 0 0 .2.3.2.7 0 .5-.2 1-.8 1.4-.2.2-.3.4-.2.6 0 0 .2.6-.1 1.1-.3.5-.9.9-1.8 1.1-.8.2-1.8.2-3 .1h-.1c-2.7.1-5.4-.3-5.4-.3H8v-7.2c0-.2 0-.4-.1-.5.1-.3.3-.9.8-1.4 1.9-1.5 3.7-6.5 3.8-6.7v-.3c-.1-.5 0-1 .1-1.2.2 0 .8.1 1.2.6.4.6.4 1.6-.1 3-.7 2.1-.7 3.2-.2 3.7.3.2.6.3.9.2.3-.1.5-.1.7-.1h.1c1.3-.3 3.6-.5 4.4.3.7.6.2 1.4.1 1.5-.2.2-.1.5.1.7 0 0 .4.4.5 1 0 .3-.2.6-.5 1z",
                        },
                      }),
                    ]
                  ),
                  _c("p", { staticClass: "ml-2" }, [_vm._v("Like")]),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "flex justify-center w-full p-4 rounded hover:bg-gray-100",
                  on: {
                    click: function ($event) {
                      return _vm.loadComments(_vm.post.id)
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "w-5 h-5 fill-current",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 24 24",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M20.3 2H3.7C2 2 .6 3.4.6 5.2v10.1c0 1.7 1.4 3.1 3.1 3.1V23l6.6-4.6h9.9c1.7 0 3.1-1.4 3.1-3.1V5.2c.1-1.8-1.3-3.2-3-3.2zm1.8 13.3c0 1-.8 1.8-1.8 1.8H9.9L5 20.4V17H3.7c-1 0-1.8-.8-1.8-1.8v-10c0-1 .8-1.8 1.8-1.8h16.5c1 0 1.8.8 1.8 1.8v10.1zM6.7 6.7h10.6V8H6.7V6.7zm0 2.9h10.6v1.3H6.7V9.6zm0 2.8h10.6v1.3H6.7v-1.3z",
                        },
                      }),
                    ]
                  ),
                  _c("p", { staticClass: "ml-2" }, [
                    _vm._v(_vm._s(_vm.$t("message.tocomment"))),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "flex justify-center w-full p-4 rounded hover:bg-gray-100",
                  on: {
                    click: function ($event) {
                      return _vm.reportAbuse(_vm.post.id)
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "w-5 h-5 fill-current",
                      attrs: {
                        version: "1.0",
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "1280.000000pt",
                        height: "1126.000000pt",
                        viewBox: "0 0 1280.000000 1126.000000",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            transform:
                              "translate(0.000000,1126.000000) scale(0.100000,-0.100000)",
                            fill: "#000000",
                            stroke: "none",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M6201 11240 c-41 -10 -113 -37 -160 -61 -70 -35 -105 -62 -187 -144\n-61 -60 -124 -134 -157 -185 -85 -132 -681 -1182 -2962 -5215 -793 -1402\n-1714 -3032 -2047 -3620 -333 -589 -617 -1098 -631 -1131 -79 -187 -72 -394\n19 -559 15 -28 64 -86 108 -130 91 -90 177 -139 306 -175 l76 -20 5879 2 5880\n3 81 27 c363 124 494 499 304 878 -21 43 -899 1580 -1951 3417 -1052 1836\n-2308 4029 -2791 4873 -484 844 -909 1580 -946 1635 -118 177 -268 311 -419\n373 -125 52 -272 64 -402 32z m1607 -3410 c793 -1383 2019 -3523 2724 -4755\nl1283 -2240 -2712 -3 c-1492 -1 -3934 -1 -5427 0 l-2715 3 1666 2945 c3188\n5637 3725 6583 3734 6572 4 -4 655 -1139 1447 -2522z",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M6290 7874 c-14 -3 -61 -14 -104 -25 -390 -98 -706 -474 -706 -837 0\n-46 22 -254 50 -461 27 -207 113 -857 190 -1446 201 -1535 199 -1517 216\n-1581 42 -165 141 -297 271 -361 67 -33 86 -38 168 -41 152 -7 246 30 348 136\n99 105 144 224 176 464 11 84 61 462 111 841 49 378 131 996 180 1375 50 378\n100 756 111 840 24 182 25 305 4 387 -82 323 -360 599 -693 686 -75 20 -266\n33 -322 23z",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M6322 2739 c-345 -44 -594 -371 -552 -726 20 -166 86 -301 204 -410\n114 -107 237 -160 391 -170 187 -11 336 47 475 187 134 134 192 273 193 465 1\n116 -13 183 -58 280 -120 261 -379 409 -653 374z",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" " + _vm._s(_vm.$t("message.reportabuse"))),
                  _c("p"),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.comments, function (comment, commentKey) {
            return _c(
              "div",
              { key: commentKey, staticClass: "flex items-center my-4" },
              [
                _c(
                  "div",
                  { staticClass: "w-8" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/" + comment.postedBy.id } },
                      [
                        _c("img", {
                          staticClass: "w-8 h-8",
                          attrs: { src: comment.postedBy.avatarUri },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "flex-1 ml-4" }, [
                  _c(
                    "div",
                    { staticClass: "text-sm bg-gray-200 rounded-lg p2" },
                    [
                      _c("p", { staticClass: "text-lg" }, [
                        _c(
                          "a",
                          { attrs: { href: "/" + comment.postedBy.id } },
                          [_vm._v(_vm._s(comment.postedBy.displayName))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "inline" }, [
                        _vm._v(_vm._s(comment.text)),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.admin
                  ? _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.deleteComment(comment.id)
                          },
                        },
                      },
                      [_c("p", [_vm._v("DEL")])]
                    )
                  : _vm._e(),
              ]
            )
          }),
          _vm._v(" "),
          _vm.showMore
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "p-2" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/comments/" + _vm.post.id } },
                      [_vm._v("...more")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.comments
            ? _c("div", { staticClass: "flex w-full" }, [
                _c("div", { staticClass: "w-full" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.commentBody,
                        expression: "commentBody",
                      },
                    ],
                    staticClass: "w-full h-8 pl-4 bg-gray-200 rounded",
                    attrs: { type: "text", name: "comment" },
                    domProps: { value: _vm.commentBody },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.commentBody = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _vm.commentBody.length > 0
                  ? _c(
                      "button",
                      {
                        staticClass: "px-3 py-1 ml-2 bg-gray-200 rounded",
                        on: {
                          click: function ($event) {
                            return _vm.postComment(_vm.post.id, _vm.commentBody)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("message.post")) +
                            "\n                    "
                        ),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex justify-between px-4 text-sm text-gray-700" },
            [
              _c("div", [
                _vm.post.likesLength != 1
                  ? _c("p", [_vm._v(_vm._s(_vm.post.likesLength) + " likes")])
                  : _c("p", [_vm._v("1 like")]),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm.post.commentsLength != 1
                  ? _c("p", [
                      _vm._v(
                        _vm._s(_vm.post.commentsLength) +
                          "  " +
                          _vm._s(_vm.$t("message.comments"))
                      ),
                    ])
                  : _c("p", [
                      _vm._v("1  " + _vm._s(_vm.$t("message.comment"))),
                    ]),
              ]),
              _vm._v(" "),
              _vm.authUser && (_vm.admin || _vm.post.user.id == _vm.authUser.id)
                ? _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.deletePost(_vm.post.id)
                        },
                      },
                    },
                    [_c("p", [_vm._v("DELETE")])]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showkeep == "true"
                ? _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.keepPost(_vm.post.id)
                        },
                      },
                    },
                    [_c("p", [_vm._v("KEEP POST")])]
                  )
                : _vm._e(),
            ]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }