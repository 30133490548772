<template>
  <div>Oliver Bachmann, Luisenstr. 34, 76137 Karlsruhe, supermodnet@gmail.com</div>
</template>

<script>
export default {
  name: "LegalNotice",
}
</script>

<style>

</style>