<template>
 <div class="mt-6 overflow-hidden bg-white rounded shadow">
        <div class="flex flex-col p-4">
            <div class="flex items-center">
                <div class="w-8">
                    <img :src="comment.postedBy.avatarUri" class="object-cover w-8 h-8 rounded-full">
                </div>
                <div class="ml-6">
                    <div>{{ comment.postedBy.displayName }}</div>
                </div>
                <div class="ml-6">
                    <div>{{ comment.text }}</div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
export default {
    name: "Comment",
    props: ["comment"],

}
</script>

<style>

</style>