import axios from "axios"

const state = {
    newsPosts: null,
    newsPostStatus : null,
    postMessage : '',
    commentUploadedFilename : '',
    totalPosts : 0
}

const getters = {
  newsPosts : state => {
      return state.newsPosts
  },
  newsStatus: state => {
    return {
      newsPostsStatus : state.newsPostStatus,
    }
  },
  postMessage: state => {
    return state.postMessage;
  },
  totalPosts: state => {
    return state.totalPosts
  }
}


const actions = {
  fetchNewsPosts({ commit, state }, data) {
    commit('setPostsStatus', 'loading');
    console.error("DATA.PAGE2:"+data.page);
    axios.get('/api/groupposts/'+data.page+'/0').then(resp => {
      commit('setNewsPosts', resp.data);
      commit('setPostsStatus', 'success');
    data.that.loading = false;
  }).catch(error => {
    commit('setPostsStatus', 'error');
   data.that.loading = false;
  });
},
getTotalPosts({commit, state}) {
  axios.get('/api/totalposts').then(resp => {
    console.log("GOT TOTALPOSTS");
    console.log(resp.data);
    console.log(resp.data.totalPosts);
    commit('updateTotalPosts', resp.data.totalPosts);
    }).catch(error => {
    });
},
postMessage({commit, state}, that) {
  commit('setPostsStatus', 'loading');

  axios.post('/api/postmessage/0', { body: state.postMessage, uploadedFilename : state.commentUploadedFilename })
  .then(res => {

    that.loading = false;
    if (res.data.status=='error') {
      alert(res.data.details);
    } else {
    commit('pushPost', res.data);
    commit('updateMessage', '')
    }

  }).catch(error => {
    that.loading = false;
    commit('setPostsStatus', 'error');
  })
},
likePost({commit, state}, data) {
  axios.post('/api/posts/like/'+data.postId)
  .then(res => {
    commit('pushLikes', { likes: res.data, postKey: data.postKey });
    commit('updateMessage', '');
  }).catch(error => {
    commit('setPostsStatus', 'error');
  })
},
removePost({commit, state}, id) {
   commit('removePost', id);
}

}

  const mutations = {
    setNewsPosts(state, posts) {
      state.newsPosts = posts;
    },
    setPostsStatus(state, status) {
      state.newsPostsStatus = status;
    },
    updateMessage(state, message) {
      console.log("Setting postMessagge to "+message+"*");
      state.postMessage = message;
    },
    updateCommentUploadedFilename(state, filename) {
      state.commentUploadedFilename = filename;
    },
    updateTotalPosts(state, totalPosts) {
      state.totalPosts = totalPosts;
    },
    pushPost(state, post) {
      console.log("PUSHING POST:");
      console.error(post);
      state.newsPosts.splice(0, 0, post);

    },
    removePost(state, id) {
      let delIndex = -1;
      for (var i = 0; i < state.newsPosts.length; i++) {
        console.log(state.newsPosts[i].id+"=="+id);
          if (state.newsPosts[i].id==id) {
              delIndex = i;
          }
      }
      if (delIndex != -1) {
      state.newsPosts.splice(delIndex, 1);
      }
    },
    pushLikes(state, data) {
      console.log("PUSHLIKES");
      console.log("DATA.LIKES:"+data.likes);
      console.log("DATA.postkey:"+data.postKey);
      console.log("DATA.likesLenght:");
      console.log(state.newsPosts);
      console.log(state.newsPosts[data.postKey]);
      console.log(state.newsPosts[data.postKey].likesLength);
      state.newsPosts[data.postKey].likesLength=data.likes.likesLength;
    }
  }

  export default {
    state, getters, actions, mutations
  }