<template>

 <div class="flex flex-col items-center">
  <vue-ads-pagination
            :total-items="totalGroupMembers"
            :max-visible-pages="5"
            :page="page"
            :loading="totalGroupMembers == 0"
            @page-change="pageChange"
            @range-change="rangeChange"
        >
            <template slot-scope="props">
                <div class="vue-ads-pr-2 vue-ads-leading-loose">
                    {{ $t('message.items') }} {{ props.start }} {{ $t('message.to') }} {{ props.end }} {{ $t('message.of') }} {{ props.total }}
                </div>
            </template>
            <template
                slot="buttons"
                slot-scope="props"
            >
                <vue-ads-page-button
                    v-for="(button, key) in props.buttons"
                    :key="key"
                    v-bind="button"
                    @page-change="page = button.page"
                />
            </template>
        </vue-ads-pagination>
        <div v-if="groupMembersList.length > 0">
        <GroupMember :page="page" v-for="(groupMember, groupMemberKey) in groupMembersList" :key="groupMemberKey" :groupMember="groupMember" />
   </div>
   <div v-else>
       {{ $t('message.nogroupmembers') }}
    </div>
    <div @click="clicked">
    <vue-ads-pagination
            :total-items="totalGroupMembers"
            :max-visible-pages="5"
            :page="page"
            :loading="totalGroupMembers == 0"
            @page-change="pageChange"
            @range-change="rangeChange"
        >
            <template slot-scope="props">
                <div class="vue-ads-pr-2 vue-ads-leading-loose">
                    {{ $t('message.items') }} {{ props.start }} {{ $t('message.to') }} {{ props.end }} {{ $t('message.of') }} {{ props.total }}
                </div>
            </template>
            <template
                slot="buttons"
                slot-scope="props"
            >
                <vue-ads-page-button
                    v-for="(button, key) in props.buttons"
                    :key="key"
                    v-bind="button"
                    @page-change="page = button.page"
                />
            </template>
        </vue-ads-pagination>
        </div>
  </div>
</template>

<script>
import '../../../node_modules/@fortawesome/fontawesome-free/css/all.css';
import '../../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';

import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import GroupMember from './GroupMember';
import { mapGetters } from 'vuex';
export default {
    name: 'ShowGroupMembers',
    components: {
        GroupMember,
        VueAdsPagination,
        VueAdsPageButton,
    },
    computed: {
        ...mapGetters({
            groupMembersList: 'groupMembersList',
            totalGroupMembers: 'totalGroupMembers',
        }),
    },
    methods: {
        clicked() {
            console.error("CLICKED");
        this.$emit('scrollToTop');
        },
        pageChange (page) {
        },
        rangeChange (start, end) {
            let page = 0;
            if (start > 0) {
                page = Math.floor(start / 10);
            }
            this.$store.dispatch('fetchGroupMembers', { page: this.page, groupid: this.$route.params.id });

            this.doScroll = true;
        }
    },
    mounted() {
      console.log("GETTING POSTS2");
      this.$store.dispatch('fetchGroupMembers',  { page: this.page, groupid: this.$route.params.id });
      this.$store.dispatch('getTotalGroupMembers',  { groupid: this.$route.params.id });
    }, data() {
      return {
        page: 0,
        doScroll : true,
        loading: false,
      }
    }

}
</script>

<style>

</style>