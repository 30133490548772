var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-4 bg-white rounded shadow w-100" }, [
    _c(
      "div",
      { staticClass: "p-4" },
      [
        _c("div", { staticClass: "flex flex-col" }, [
          _c("img", {
            staticClass: "w-8 h-8 rounded-full",
            attrs: { src: _vm.joinRequest.user.avatarUri },
          }),
          _vm._v(
            _vm._s(_vm.joinRequest.user.firstName) +
              " " +
              _vm._s(_vm.joinRequest.user.lastName) +
              "\n                   "
          ),
        ]),
        _vm._v(
          "\n                   " +
            _vm._s(_vm.$t("message.tothegroup")) +
            ":\n                   "
        ),
        _c(
          "router-link",
          {
            staticClass: "text-blue-700",
            attrs: { to: "/groupdetailsfeed/" + _vm.joinRequest.groupId },
          },
          [_vm._v(_vm._s(_vm.joinRequest.groupName))]
        ),
        _vm._v(" "),
        _c("p", [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.joinRequest.reason,
                expression: "joinRequest.reason",
              },
            ],
            attrs: { cols: "40", rows: "5", disabled: "" },
            domProps: { value: _vm.joinRequest.reason },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.joinRequest, "reason", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("input", {
          staticClass:
            "px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline",
          attrs: { type: "button", value: "Confirm" },
          on: {
            click: function ($event) {
              return _vm.confirm(_vm.joinRequest.id)
            },
          },
        }),
        _vm._v(" "),
        _c("input", {
          staticClass:
            "px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline",
          attrs: { type: "button", value: "Deny" },
          on: {
            click: function ($event) {
              return _vm.deny(_vm.joinRequest.id)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }