var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-6 overflow-hidden bg-white rounded shadow" },
    [
      _c("div", { staticClass: "flex flex-col p-4" }, [
        _c(
          "div",
          { staticClass: "flex items-center" },
          [
            _c(
              "router-link",
              { staticClass: "px-6", attrs: { to: "/" + _vm.user.userName } },
              [
                _c("div", { staticClass: "w-8" }, [
                  _c("img", {
                    staticClass: "object-cover w-8 h-8 rounded-full",
                    attrs: { src: _vm.user.avatarUri },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ml-4" }, [
                  _c("div", [_vm._v(_vm._s(_vm.user.displayName))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ml-4" }, [
                  _c("div", [
                    _vm._v(
                      "(" +
                        _vm._s(_vm.user.postSize) +
                        " " +
                        _vm._s(_vm.$t("message.posts")) +
                        ")"
                    ),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.authUser
              ? _c(
                  "div",
                  {
                    staticClass: "ml-4",
                    on: {
                      click: function ($event) {
                        return _vm.reportUser(_vm.user.id)
                      },
                    },
                  },
                  [_vm._v("\n                   Report User\n               ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }