var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-6 overflow-hidden bg-white rounded shadow" },
    [
      _c("div", { staticClass: "flex flex-col p-4" }, [
        _c("div", { staticClass: "flex items-center" }, [
          _c("div", { staticClass: "w-8" }, [
            _c("img", {
              staticClass: "object-cover w-8 h-8 rounded-full",
              attrs: { src: _vm.comment.postedBy.avatarUri },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ml-6" }, [
            _c("div", [_vm._v(_vm._s(_vm.comment.postedBy.displayName))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ml-6" }, [
            _c("div", [_vm._v(_vm._s(_vm.comment.text))]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }