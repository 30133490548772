import Vue from 'vue'
import VueRouter from 'vue-router'

import UserShow from './components/Users/Show'
import NewsFeed from './components/NewsFeed'
import Userlist from './components/Userlist'
import UserAbusingList from './components/UserAbusingList'
import Profile from './components/Profile/Profile'
import FriendRequests from './components/FriendRequests'
import Register from './components/Register'
import Login from './components/Login'
import Logout from './components/Logout'
import LegalNotice from './components/LegalNotice'
import ResetPassword from './components/ResetPassword'
import ShowProfile from './components/Profile/ShowProfile'
import GroupsFeed from './components/GroupsFeed'
import GroupDetailsFeed from './components/GroupDetailsFeed'
import JoinRequests from './components/JoinRequests'
import Comments from './components/Comments'
import AbusedPosts from './components/AbusedPosts'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
    routes:
    [ {
      path: '/',
      name: 'posts.newsfeed',
      meta: { title: 'News Feed' },
      component: NewsFeed
    },{
      path: '/abusedposts',
      name: 'posts.abusedposts',
      meta: { title: 'Abused posts' },
      component: AbusedPosts
    },{
      path: '/groups',
      name: 'groups.groupsFeed',
      meta: { title: 'Groups Feed' },
      component: GroupsFeed
    },
        {
          path: '/userlist',
          name: 'user.list',
          component: Userlist,
          meta: { title: 'User list' }
        },
        {
          path: '/userabusinglist',
          name: 'user.abusinglist',
          component: UserAbusingList,
          meta: { title: 'User abusing list' }
        },
        {
          path: '/profile',
          name: 'profile',
          component: Profile,
          meta: { title: 'Profile' }
        },{
          path: '/register',
          name: 'register',
          component: Register,
          meta: { title: 'Register' }
        }, {
          path: '/friendrequests',
          name: 'friendrequests',
          component: FriendRequests,
          meta: { title: 'Friend request' }
        }, {
          path: '/login',
          name: 'login',
          component: Login,
          meta: { title: 'Login' }
        }, {
          path: '/logout',
          name: 'logout',
          component: Logout,
          meta: { title: 'Logout' }
        },
        {
        path: '/legalnotice',
        name: 'legalnotice',
        component: LegalNotice,
        meta: { title: 'Legal Notice' }
      },
      {
        path: '/resetpassword',
        name: 'resetpassword',
        component: ResetPassword,
        meta: { title: 'Reset Password' }
      },
      {
        path: '/showprofile/:id',
        name: 'showprofile',
        component: ShowProfile,
        props: true,
        meta: { title: 'Show Profile' }
      },
      {
        path: '/groupdetailsfeed/:id',
        name: 'groupdetailsfeed',
        component: GroupDetailsFeed,
        props: true,
        meta: { title: 'Group Details Feed' }
      },
      {
        path: '/joinrequests',
        name: 'joinrequests',
        component: JoinRequests,
        meta: { title: 'Group join requests' }
      },
      { path: '/:userId',
      name: 'user.show',
      component: UserShow,
      meta: { title: 'User posts'} },
      {
        path: '/comments/:postid',
        name: 'comments',
        component: Comments,
        meta: { title: 'Comments' }
      }
    ]
})

export default router