<template>
 <div class="mt-6 overflow-hidden bg-white rounded shadow">
        <div class="flex flex-col p-4">
            <div class="flex items-center">
                <div class="w-8">
                    <router-link :to="'/'+post.user.userName">
                    <img :src="post.user.avatarUri" class="object-cover w-8 h-8 rounded-full">
                    </router-link>
                </div>
                <div class="ml-6">
                    <div>{{ post.user.displayName }}</div>
                    <div>{{ post.humanCreatedAt }} </div>
                </div>
            </div>
            <div class="mt-4">{{ post.body }}</div>

            <div class="w-full" v-if="post.photo!=null">
                <div v-if="post.photoType=='mp4'">
                    <vue-plyr>
                        <video
                            controls
                            crossorigin
                            playsinline
                        >
                            <source
                            :src="post.photo"
                            type="video/mp4"
                            />
                        </video>
                        </vue-plyr>
                </div>
                <div v-else>
                    <img :src="post.photo" class="w-full">
                </div>
            </div>
            <div class="flex justify-between m-4 border-gray-400 border-1">
                    <button @click="likePost" class="flex justify-center w-full p-4 rounded hover:bg-gray-100 " :class="post.logged_in_user_likes_post == 1 ? `bg-blue-600 text-white hover:text-black` : ``">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5 fill-current">
                        <path d="M20.8 15.6c.4-.5.6-1.1.6-1.7 0-.6-.3-1.1-.5-1.4.3-.7.4-1.7-.5-2.6-.7-.6-1.8-.9-3.4-.8-1.1.1-2 .3-2.1.3-.2 0-.4.1-.7.1 0-.3 0-.9.5-2.4.6-1.8.6-3.1-.1-4.1-.7-1-1.8-1-2.1-1-.3 0-.6.1-.8.4-.5.5-.4 1.5-.4 2-.4 1.5-2 5.1-3.3 6.1l-.1.1c-.4.4-.6.8-.8 1.2-.2-.1-.5-.2-.8-.2H3.7c-1 0-1.7.8-1.7 1.7v6.8c0 1 .8 1.7 1.7 1.7h2.5c.4 0 .7-.1 1-.3l1 .1c.2 0 2.8.4 5.6.3.5 0 1 .1 1.4.1.7 0 1.4-.1 1.9-.2 1.3-.3 2.2-.8 2.6-1.6.3-.6.3-1.2.3-1.6.8-.8 1-1.6.9-2.2.1-.3 0-.6-.1-.8zM3.7 20.7c-.3 0-.6-.3-.6-.6v-6.8c0-.3.3-.6.6-.6h2.5c.3 0 .6.3.6.6v6.8c0 .3-.3.6-.6.6H3.7zm16.1-5.6c-.2.2-.2.5-.1.7 0 0 .2.3.2.7 0 .5-.2 1-.8 1.4-.2.2-.3.4-.2.6 0 0 .2.6-.1 1.1-.3.5-.9.9-1.8 1.1-.8.2-1.8.2-3 .1h-.1c-2.7.1-5.4-.3-5.4-.3H8v-7.2c0-.2 0-.4-.1-.5.1-.3.3-.9.8-1.4 1.9-1.5 3.7-6.5 3.8-6.7v-.3c-.1-.5 0-1 .1-1.2.2 0 .8.1 1.2.6.4.6.4 1.6-.1 3-.7 2.1-.7 3.2-.2 3.7.3.2.6.3.9.2.3-.1.5-.1.7-.1h.1c1.3-.3 3.6-.5 4.4.3.7.6.2 1.4.1 1.5-.2.2-.1.5.1.7 0 0 .4.4.5 1 0 .3-.2.6-.5 1z"/>
                        </svg><p class="ml-2">Like</p>
                    </button>
                    <button @click="loadComments(post.id)" class="flex justify-center w-full p-4 rounded hover:bg-gray-100"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5 fill-current"><path d="M20.3 2H3.7C2 2 .6 3.4.6 5.2v10.1c0 1.7 1.4 3.1 3.1 3.1V23l6.6-4.6h9.9c1.7 0 3.1-1.4 3.1-3.1V5.2c.1-1.8-1.3-3.2-3-3.2zm1.8 13.3c0 1-.8 1.8-1.8 1.8H9.9L5 20.4V17H3.7c-1 0-1.8-.8-1.8-1.8v-10c0-1 .8-1.8 1.8-1.8h16.5c1 0 1.8.8 1.8 1.8v10.1zM6.7 6.7h10.6V8H6.7V6.7zm0 2.9h10.6v1.3H6.7V9.6zm0 2.8h10.6v1.3H6.7v-1.3z"/></svg><p class="ml-2">{{ $t('message.tocomment') }}</p></button>
                    <button @click="reportAbuse(post.id)" class="flex justify-center w-full p-4 rounded hover:bg-gray-100"><svg version="1.0" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 fill-current" width="1280.000000pt" height="1126.000000pt" viewBox="0 0 1280.000000 1126.000000"><g transform="translate(0.000000,1126.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M6201 11240 c-41 -10 -113 -37 -160 -61 -70 -35 -105 -62 -187 -144
-61 -60 -124 -134 -157 -185 -85 -132 -681 -1182 -2962 -5215 -793 -1402
-1714 -3032 -2047 -3620 -333 -589 -617 -1098 -631 -1131 -79 -187 -72 -394
19 -559 15 -28 64 -86 108 -130 91 -90 177 -139 306 -175 l76 -20 5879 2 5880
3 81 27 c363 124 494 499 304 878 -21 43 -899 1580 -1951 3417 -1052 1836
-2308 4029 -2791 4873 -484 844 -909 1580 -946 1635 -118 177 -268 311 -419
373 -125 52 -272 64 -402 32z m1607 -3410 c793 -1383 2019 -3523 2724 -4755
l1283 -2240 -2712 -3 c-1492 -1 -3934 -1 -5427 0 l-2715 3 1666 2945 c3188
5637 3725 6583 3734 6572 4 -4 655 -1139 1447 -2522z"/>
<path d="M6290 7874 c-14 -3 -61 -14 -104 -25 -390 -98 -706 -474 -706 -837 0
-46 22 -254 50 -461 27 -207 113 -857 190 -1446 201 -1535 199 -1517 216
-1581 42 -165 141 -297 271 -361 67 -33 86 -38 168 -41 152 -7 246 30 348 136
99 105 144 224 176 464 11 84 61 462 111 841 49 378 131 996 180 1375 50 378
100 756 111 840 24 182 25 305 4 387 -82 323 -360 599 -693 686 -75 20 -266
33 -322 23z"/>
<path d="M6322 2739 c-345 -44 -594 -371 -552 -726 20 -166 86 -301 204 -410
114 -107 237 -160 391 -170 187 -11 336 47 475 187 134 134 192 273 193 465 1
116 -13 183 -58 280 -120 261 -379 409 -653 374z"/>
</g>
</svg>&nbsp;{{ $t('message.reportabuse') }}</p></button>
            </div>
            <div class="flex items-center my-4" v-for="(comment, commentKey) in comments" v-bind:key="commentKey">
                <div class="w-8">
                    <router-link :to="'/'+comment.postedBy.id">
                    <img :src="comment.postedBy.avatarUri" class="w-8 h-8">
                    </router-link>
                </div>
                <div class="flex-1 ml-4">
                    <div class="text-sm bg-gray-200 rounded-lg p2">
                    <p class="text-lg"><a :href="'/'+comment.postedBy.id">{{comment.postedBy.displayName }}</a></p>
                    <p class="inline">{{comment.text}}</p>
                    </div>

                </div>
                 <div @click="deleteComment(comment.id)" v-if="admin">
                        <p>DEL</p>
                    </div>
            </div>
            <div v-if="showMore">
                <div class="p-2"><router-link :to="'/comments/'+post.id">...more</router-link></div>
            </div>
            <div v-if="comments" class="flex w-full">
                    <div class="w-full">
                        <input v-model="commentBody" type="text" name="comment" class="w-full h-8 pl-4 bg-gray-200 rounded">
                    </div>
                    <button v-if="commentBody.length>0" @click="postComment(post.id, commentBody);" class="px-3 py-1 ml-2 bg-gray-200 rounded">
                        {{ $t('message.post') }}
                    </button>
            </div>
            <div class="flex justify-between px-4 text-sm text-gray-700">
                   <div>
                        <p v-if="post.likesLength != 1">{{ post.likesLength }} likes</p>
                        <p v-else>1 like</p>
                    </div>
                    <div>
                         <p v-if="post.commentsLength != 1">{{ post.commentsLength }}  {{ $t('message.comments') }}</p>
                        <p v-else>1  {{ $t('message.comment') }}</p>
                    </div>
                    <div @click="deletePost(post.id)" v-if="authUser && (admin || post.user.id == authUser.id)">
                        <p>DELETE</p>
                    </div>
                    <div @click="keepPost(post.id)" v-if="showkeep=='true'">
                        <p>KEEP POST</p>
                    </div>
            </div>
        </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from 'vuex';
export default {
    name: "Post",
    props: ["post", "postKey", "showkeep"],
    methods: {
        likePost() {
            this.$emit('like', { postId: this.post.id, postKey: this.postKey });
        },
        loadComments(id) {
            axios.post('/api/postcomments/'+id)
            .then(res => {
                this.comments = res.data;
                if (this.comments.length>=50) {
                    console.error("SHOWMORE!!");
                    this.showMore = true;
                }
            }).catch(error => {
                console.error("An error occured posting the comment");
                console.error(error);
            })
        },
        postComment(id, body) {
                axios.post('/api/postcomment', { 'post_id' : id, 'body': body})
                            .then(res => {
                                if (res.data.poststatus=='error') {
                                    alert(res.data.details);
                                } else {
                                this.comments.push(res.data);
                                this.post.commentsLength++;
                                this.commentBody = '';
                                }
                            }).catch(error => {
                                console.error("An error occured loading the comments");
                            });
        },
        deletePost(id) {
             if (confirm('Are you sure?')) {
                axios.post('/api/deletepost', { 'post_id' : id })
                            .then(res => {
                                console.log("Deleted post");
                                this.$store.dispatch('removePost', id);
                            }).catch(error => {
                                console.error("An error occured deleting the posts");
                            });
             }
        },
        reportAbuse(id) {
             if (confirm('Do you really want to report this post?')) {
                axios.post('/api/reportpost', { 'post_id' : id })
                            .then(res => {
                                alert('This post has been reported');
                            }).catch(error => {
                                console.error("An error occured reporting the posts");
                            });
             }
        },
         keepPost(id) {
             if (confirm('Do you really want to keep the post?')) {
                axios.post('/api/keeppost', { 'post_id' : id })
                            .then(res => {
                                console.log("Kept post");
                                this.$store.dispatch('removePost', id);
                            }).catch(error => {
                                console.error("An error occured keeping the posts");
                            });
             }
        },
         deleteComment(id) {
             if (confirm('Are you sure?')) {
                axios.post('/api/deletecomment', { 'comment_id' : id })
                            .then(res => {
                                console.log("Deleted comment");
                            }).catch(error => {
                                console.error("An error occured deleting the comment");
                            });
             }
        }
      }, data : () => {
        return {
            comments : null,
            commentBody : '',
            showMore : false,
        }
    }, computed: {
        ...mapGetters({
            admin: 'admin',
            authUser : 'authUser'
        }),

    }

}
</script>

<style>

</style>