import axios from "axios"

const state = {
    user: null,
    userStatus : null,
    admin : false,
}

const getters = {
    authUser: state => {
        return state.user
    },
    admin: state => {
        console.error("RETURNING ADMIN:"+state.admin);
        return state.admin
    }
    /*getUser: state => {
        return state.user
    }*/
}

const mutations = {
    setAuthUser(state, user) {
        state.user = user
    },
    setAdmin(state, admin) {
        console.log("SETTING ADMIN TO "+admin);
        state.admin = admin;
    }
}

const actions = {
    fetchAuthUser({
        commit, state
    }) {
        axios.get('/api/auth-user').then(res => {
            console.log("Loaded user data");
            console.log(res.data);
            commit('setAuthUser', res.data);
            let isAdmin = false;
            console.error("LEN:"+res.data.roles.length);

            for (let i = 0; i < res.data.roles.length; i++) {
                if(res.data.roles[i].indexOf("ADMIN")!=-1) {
                    console.log("ADMIN = TRUE");
                    isAdmin = true;
                }
            }
            commit('setAdmin', isAdmin);

        }).catch(error => {
            console.log('Unable to fetch auth user');
        })
    }

    /*setUser(context, data) {
        console.log("USER");
        context.commit('setUser', data)
    }*/
}

export default {
     state, getters, mutations, actions
}