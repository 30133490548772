var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid h-screen place-items-center" }, [
    _vm.loading
      ? _c("div", { staticClass: "w-full max-w-xs" }, [_vm._m(0)])
      : _c("div", [
          _c("div", { staticClass: "w-full max-w-xs" }, [
            _c(
              "form",
              { staticClass: "px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md" },
              [
                _c("div", { staticClass: "mb-4" }, [
                  _c(
                    "label",
                    {
                      staticClass: "block mb-2 text-sm font-bold text-gray-700",
                      attrs: { for: "username" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("message.username")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.username,
                        expression: "form.username",
                      },
                    ],
                    staticClass:
                      "w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
                    attrs: {
                      id: "username",
                      type: "text",
                      placeholder: "Username",
                    },
                    domProps: { value: _vm.form.username },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "username", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-red-500" }, [
                    _vm._v(_vm._s(this.errors.username)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-4" }, [
                  _c(
                    "label",
                    {
                      staticClass: "block mb-2 text-sm font-bold text-gray-700",
                      attrs: { for: "email_address" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("message.emailaddress")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.emailaddress,
                        expression: "form.emailaddress",
                      },
                    ],
                    staticClass:
                      "w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
                    attrs: {
                      id: "emailaddress",
                      type: "text",
                      placeholder: "Email address",
                    },
                    domProps: { value: _vm.form.emailaddress },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "emailaddress", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-red-500" }, [
                    _vm._v(_vm._s(this.errors.emailaddress)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-4" }, [
                  _c(
                    "label",
                    {
                      staticClass: "block mb-2 text-sm font-bold text-gray-700",
                      attrs: { for: "firstname" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("message.firstname")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.firstname,
                        expression: "form.firstname",
                      },
                    ],
                    staticClass:
                      "w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
                    attrs: {
                      id: "firstname",
                      type: "text",
                      placeholder: "First name",
                    },
                    domProps: { value: _vm.form.firstname },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "firstname", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-red-500" }, [
                    _vm._v(_vm._s(this.errors.firstname)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-4" }, [
                  _c(
                    "label",
                    {
                      staticClass: "block mb-2 text-sm font-bold text-gray-700",
                      attrs: { for: "lastname" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("message.lastname")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.lastname,
                        expression: "form.lastname",
                      },
                    ],
                    staticClass:
                      "w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
                    attrs: {
                      id: "lastname",
                      type: "text",
                      placeholder: "Last name",
                    },
                    domProps: { value: _vm.form.lastname },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "lastname", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-red-500" }, [
                    _vm._v(_vm._s(this.errors.lastname)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-6" }, [
                  _c(
                    "label",
                    {
                      staticClass: "block mb-2 text-sm font-bold text-gray-700",
                      attrs: { for: "password" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("message.password")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.password,
                        expression: "form.password",
                      },
                    ],
                    staticClass:
                      "w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
                    attrs: {
                      id: "password",
                      type: "password",
                      placeholder: "******************",
                    },
                    domProps: { value: _vm.form.password },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "password", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-red-500" }, [
                    _vm._v(_vm._s(this.errors.password)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-6" }, [
                  _c(
                    "label",
                    {
                      staticClass: "block mb-2 text-sm font-bold text-gray-700",
                      attrs: { for: "password" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("message.passwordrepetition")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.password2,
                        expression: "form.password2",
                      },
                    ],
                    staticClass:
                      "w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
                    attrs: {
                      id: "password2",
                      type: "password",
                      placeholder: "******************",
                    },
                    domProps: { value: _vm.form.password2 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "password2", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-red-500" }, [
                    _vm._v(_vm._s(this.errors.password2)),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex items-center justify-between" },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline",
                        attrs: { type: "button" },
                        on: { click: _vm.checkForm },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("message.signup")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      { staticClass: "px-6", attrs: { to: "/login" } },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "inline-block text-sm font-bold text-blue-500 align-baseline hover:text-blue-800",
                            attrs: { href: "#" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("message.login")) +
                                "?\n        "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex items-center justify-center " }, [
      _c("div", {
        staticClass:
          "w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }