<template>
 <div>
                        <nav class="flex flex-col bg-white tabs sm:flex-row">
                            <button data-target="panel-1" @click="onTabClick" :class="currentTab=='panel-1' ? `border-b-2 border-blue-500` : ``" class="block px-6 py-4 font-medium text-gray-600 text-blue-500 tab hover:text-blue-500 focus:outline-none">
                                Group posts
                            </button>
                            <button data-target="panel-2" :class="currentTab=='panel-2' ? `border-b-2 border-blue-500` : ``" @click="onTabClick" class="block px-6 py-4 text-gray-600 tab hover:text-blue-500 focus:outline-none" >
                                Group members
                            </button>
                            <button v-if="authUser && groupDetails.isOwner == 1" data-target="panel-3" :class="currentTab=='panel-3' ? `border-b-2 border-blue-500` : ``" @click="onTabClick" class="block px-6 py-4 text-gray-600 tab hover:text-blue-500 focus:outline-none" >
                                Group details
                            </button>
                             <button v-if="authUser && groupDetails.isOwner == 1" data-target="panel-4" :class="currentTab=='panel-4' ? `border-b-2 border-blue-500` : ``" @click="onTabClick" class="block px-6 py-4 text-gray-600 tab hover:text-blue-500 focus:outline-none" >
                                Group image
                            </button>
                        </nav>


<div class="py-5 panel-3 tab-content" v-if="currentTab=='panel-1'">


<div v-if="loading" class="w-full max-w-xs">
<div class="flex items-center justify-center ">
    <div class="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
    </div>
</div>
<div v-else>
     <div v-if="authUser">
         <div class="p-4 mt-4 bg-white rounded shadow ">
                <div>
                    <p>{{ groupDetails.groupName }}</p>
                    <p>{{ groupDetails.groupDescription }}</p>
                    <p>{{ groupDetails.groupTags }}</p>
                </div>
            </div>
          <div v-if="groupDetails.isRequesting == 1">
            <div class="p-4 mt-4 bg-white rounded shadow ">
                <div>
                    <p>{{ $t('message.youarewaiting') }}</p>
                </div>
            </div>

        </div>
          <div v-if="groupDetails.isOwner == 1">
            <NewOwner />
         </div>
         <div v-if="( ( (groupDetails.isOwner == 0) && (groupDetails.isMember == 0) ) && (groupDetails.isRequesting == 0) )">
            <NewJoinRequest />
         </div>
         <div>
             <div v-if="(groupDetails.isMember == 1 || groupDetails.isOwner == 1)">
            <NewGroupPost />
        </div>
   </div>
   </div>
  <vue-ads-pagination
            :total-items="totalGroupPosts"
            :max-visible-pages="5"
            :page="page"
            :loading="totalGroupPosts == 0"
            @page-change="pageChange"
            @range-change="rangeChange"
        >
            <template slot-scope="props">
                <div class="vue-ads-pr-2 vue-ads-leading-loose">
                    {{ $t('message.items') }} {{ props.start }} {{ $t('message.to') }} {{ props.end }} {{ $t('message.of') }} {{ props.total }}
                </div>
            </template>
            <template
                slot="buttons"
                slot-scope="props"
            >
                <vue-ads-page-button
                    v-for="(button, key) in props.buttons"
                    :key="key"
                    v-bind="button"
                    @page-change="page = button.page"
                />
            </template>
        </vue-ads-pagination>
  <GroupPost v-if="groupposts != null" v-for="(grouppost, groupPostKey) in groupposts" @like="userLike" :groupPostKey="groupPostKey" :key="grouppost.id" :grouppost="grouppost" />
   <vue-ads-pagination
            :total-items="totalGroupPosts"
            :max-visible-pages="5"
            :page="page"
            :loading="totalGroupPosts == 0"
            @page-change="pageChange"
            @range-change="rangeChange"
        >
            <template slot-scope="props">
                <div class="vue-ads-pr-2 vue-ads-leading-loose">
                   {{ $t('message.items') }} {{ props.start }} {{ $t('message.to') }} {{ props.end }} {{ $t('message.of') }} {{ props.total }}
                </div>
            </template>
            <template
                slot="buttons"
                slot-scope="props"
            >
                <vue-ads-page-button
                    v-for="(button, key) in props.buttons"
                    :key="key"
                    v-bind="button"
                    @page-change="page = button.page"
                />
            </template>
        </vue-ads-pagination>
   </div>
    </div>

<div class="py-5 panel-2 tab-content" v-if="currentTab=='panel-2'">

        <ShowGroupMembers />

  </div>
  <div class="py-5 panel-3 tab-content" v-if="currentTab=='panel-3'">

        <ShowGroupDetails />

  </div>
<div class="py-5 panel-3 tab-content" v-if="currentTab=='panel-4'">

       <GroupImage :group-detail="groupDetails" />
</div>


</div>
</template>

<script>
import '../../node_modules/@fortawesome/fontawesome-free/css/all.css';
import '../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';

import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import ShowGroupMembers from './GroupMembers/ShowGroupMembers';
import ShowGroupDetails from './GroupMembers/ShowGroupDetails';
import NewGroupPost from './Groups/Post/NewGroupPost';
import NewJoinRequest from './Groups/NewJoinRequest';
import GroupImage from './Groups/GroupImage';
import NewOwner from './Groups/NewOwner';
import Register from './Register';
import GroupPost from './Groups/Post/GroupPost';
import { mapGetters } from 'vuex';
export default {
    name: 'GroupDetailsFeed',
    components: {
        NewGroupPost,
        GroupPost,
        VueAdsPagination,
        VueAdsPageButton,
        Register,
        NewJoinRequest,
        NewOwner,
        ShowGroupMembers,
        ShowGroupDetails,
        GroupImage
    },
    computed: {
        ...mapGetters({
            groupposts: 'newsGroupPosts',
            newsGroupPostsStatus: 'newsGroupPostsStatus',
            totalGroupPosts: 'totalGroupPosts',
            authUser: 'authUser',
            groupDetails: 'groupDetails',
        }),
    },
    methods: {
        userLike(data) {
            console.log("SENMT DATA:"+data);
            this.$store.dispatch('likeGroupPost', data);

        },
        pageChange (page) {
            console.log("FETCH"+this.page);
            this.page = page;
            this.$store.dispatch('fetchNewsGroupPosts', { groupid: this.$route.params.id, page: this.page, that: this });
            console.log(page);
        },

        rangeChange (start, end) {
            let page = 0;
            if (start > 0) {
                page = Math.floor(start / 10);
            }
            this.$store.dispatch('fetchNewsGroupPosts', { groupid: this.$route.params.id, page: this.page, that: this });
            this.$emit('scrollToTop');
        },
        onTabClick(event) {

        let classString = event.target.getAttribute('data-target');
        this.currentTab = classString;
        console.log(this.currentTab);
        },
    },
    mounted() {
      this.$store.dispatch('fetchNewsGroupPosts', { groupid: this.$route.params.id, page: this.page, that: this});
      this.$store.dispatch('getTotalGroupPosts', this.$route.params.id);
      this.$store.dispatch('fetchGroupDetails', this.$route.params.id);
    }, data() {
      return {
          currentTab: 'panel-1',
        page: 0,
        loading: false,
      }
    }

}
</script>

<style>

</style>