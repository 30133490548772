<template>
 <div class="mb-4 bg-white rounded shadow w-100">

                  <div class="p-4">
                    <div class="flex flex-col">
                    <img :src="joinRequest.user.avatarUri" class="w-8 h-8 rounded-full">{{ joinRequest.user.firstName }} {{ joinRequest.user.lastName }}
                    </div>
                    {{ $t('message.tothegroup') }}:
                    <router-link class="text-blue-700" :to="'/groupdetailsfeed/'+joinRequest.groupId">{{ joinRequest.groupName }}</router-link>
                    <p><textarea cols="40" rows="5" disabled v-model="joinRequest.reason"></textarea></p>
                    <input @click="confirm(joinRequest.id)" type="button" value="Confirm" class="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline">
                    <input @click="deny(joinRequest.id)" type="button" value="Deny" class="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline">
                </div>
  </div>
</template>

<script>
import axios from "axios";
export default {

    name: "JoinRequest",
    props: ["joinRequest", "page"],
   methods: {
     deny(id) {
      axios.post('/api/join/deny', { 'join_request_id' : id })
                                  .then(res => {
                                      console.log("Denied join request");
                                      this.$store.dispatch('fetchJoinRequests', this.page);
                                      //this.$store.dispatch('removeJoin', id);
                                      this.$store.dispatch('getTotalJoinRequests');
                                  }).catch(error => {
                                      console.error("An error occured deleting the join request");
                                  });

     },
     confirm(id) {
        axios.post('/api/join/confirm', { 'join_request_id' : id })
                                  .then(res => {
                                      console.log("Confirmed join request");
                                      this.$store.dispatch('fetchJoinRequests', this.page);
                                      //this.$store.dispatch('removeJoin', id);
                                      this.$store.dispatch('getTotalJoinRequests');
                                  }).catch(error => {
                                      console.error("An error occured deleting the join request");
                                  });


     }
   }
}
</script>

<style>

</style>