<template>
    <div class="flex items-center h-12 bg-white">
        <div class="w-1/3">
            <div class="flex">
                <router-link to="/" class="px-6">
                    <img src="/logo.png" :alt="$t('message.home')">
                </router-link>
                <!--<div class="relative ml-2">
                    <div class="absolute text-gray-700 hover:text-red-700">
                        <svg viewBox="0 0 24 24" class="w-5 h-5 mt-2 ml-2 fill-current">
                            <path fill-rule="evenodd" d="M20.2 18.1l-1.4 1.3-5.5-5.2 1.4-1.3 5.5 5.2zM7.5 12c-2.7 0-4.9-2.1-4.9-4.6s2.2-4.6 4.9-4.6 4.9 2.1 4.9 4.6S10.2 12 7.5 12zM7.5.8C3.7.8.7 3.7.7 7.3s3.1 6.5 6.8 6.5c3.8 0 6.8-2.9 6.8-6.5S11.3.8 7.5.8z" clip-rule="evenodd"/>
                        </svg>
                </div>
                <input type="text" name="search" placeholder="Search Facebook" class="w-56 h-8 pl-8 text-sm rounded-full bg-gray--200 focus:outline-none focus:shadow-outline font-sm">
                </div>-->
            </div>
        </div>
        <div class="flex items-center justify-center w-1/3 h-full">

            <router-link v-if="authUser" to="/" :class="$route.name=='posts.newsfeed' ? `border-b-2 border-blue-500` : ``" class="flex items-center h-full px-6 fill-current">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5 fill-current"><path d="M22.6 11l-9.9-9c-.4-.4-1.1-.4-1.5 0l-9.9 9c-.3.3-.5.8-.3 1.2.2.5.6.8 1.1.8h1.6v9c0 .4.3.6.6.6h5.4c.4 0 .6-.3.6-.6v-5.5h3.2V22c0 .4.3.6.6.6h5.4c.4 0 .6-.3.6-.6v-9h1.6c.5 0 .9-.3 1.1-.7.3-.5.2-1-.2-1.3zm-2.5-8h-4.3l5 4.5V3.6c0-.3-.3-.6-.7-.6z"/>
            </svg>

            </router-link>
            <router-link v-if="authUser" :to="'/'+authUser.userName" :class="$route.name=='user.show' ? `border-b-2 border-blue-500` : ``"  class="flex items-center px-6 border-white fill-current">
                    <img :src="authUser.avatarUri" class="object-cover w-8 h-8 rounded-full">
            </router-link>
            <router-link v-if="!authUser" to="/register" class="flex items-center px-6 border-white">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="24px" height="24px" viewBox="0 0 900.000000 1280.000000">
<g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path fill="#770000" d="M4260 12794 c-585 -53 -980 -159 -1445 -389 -281 -139 -476 -263
-710 -451 -542 -435 -976 -1051 -1200 -1704 -116 -339 -178 -643 -205 -1006
-6 -84 -10 -564 -10 -1207 l0 -1067 -329 0 c-286 0 -332 -2 -345 -16 -15 -14
-16 -343 -16 -3468 0 -3332 1 -3453 18 -3469 17 -16 343 -17 4484 -17 4313 0
4465 1 4481 18 16 17 17 272 17 3470 0 3124 -1 3452 -16 3466 -13 14 -59 16
-345 16 l-329 0 0 1068 c0 1060 -3 1201 -36 1457 -131 1032 -699 1978 -1559
2596 -161 117 -316 208 -530 314 -410 203 -791 315 -1255 371 -128 15 -570 27
-670 18z m645 -1083 c316 -50 565 -130 848 -273 519 -263 944 -692 1206 -1218
138 -278 209 -503 264 -835 20 -126 21 -162 24 -1272 l4 -1143 -2751 0 -2751
0 4 1143 c3 1110 4 1146 24 1272 55 332 126 557 264 835 364 731 1031 1259
1832 1449 84 20 323 58 412 65 127 10 493 -4 620 -23z"/>
</g>
</svg>

            </router-link>
        </div>
        <div v-if="authUser" class="flex justify-end w-1/3 pr-4">
            <router-link to="/logout">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16 12.771h-3.091c-.542 0-.82-.188-1.055-.513l-1.244-1.674-2.029 2.199 1.008 1.562c.347.548.373.922.373 1.42v4.235h-1.962v-3.981c-.016-1.1-1.695-2.143-2.313-1.253l-1.176 1.659c-.261.372-.706.498-1.139.498h-3.372v-1.906l2.532-.001c.397 0 .741-.14.928-.586l1.126-2.75c.196-.41.46-.782.782-1.102l2.625-2.6-.741-.647c-.223-.195-.521-.277-.812-.227l-2.181.381-.342-1.599 2.992-.571c.561-.107 1.042.075 1.461.462l2.882 2.66c.456.414.924 1.136 1.654 2.215.135.199.323.477.766.477h2.328v1.642zm-2.982-5.042c1.02-.195 1.688-1.182 1.493-2.201-.172-.901-.96-1.528-1.845-1.528-1.186 0-2.07 1.078-1.85 2.234.196 1.021 1.181 1.69 2.202 1.495zm4.982-5.729v15l6 5v-20h-6z"/></svg>
            </router-link>
        </div>
         <div class="flex justify-end w-1/3 pr-4">
            <div v-if="this.$i18n.locale == 'de'">
                <div @click="switchLanguage('en')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="15" viewBox="0 0 5 3">
                        <rect id="black_stripe" width="5" height="3" y="0" x="0" fill="#000"/>
                        <rect id="red_stripe" width="5" height="2" y="1" x="0" fill="#D00"/>
                        <rect id="gold_stripe" width="5" height="1" y="2" x="0" fill="#FFCE00"/>
                    </svg>
                </div>
            </div>
             <div v-else>
                <div @click="switchLanguage('de')">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30" width="30" height="15">
                    <clipPath id="s">
                        <path d="M0,0 v30 h60 v-30 z"/>
                    </clipPath>
                    <clipPath id="t">
                        <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z"/>
                    </clipPath>
                    <g clip-path="url(#s)">
                        <path d="M0,0 v30 h60 v-30 z" fill="#012169"/>
                        <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" stroke-width="6"/>
                        <path d="M0,0 L60,30 M60,0 L0,30" clip-path="url(#t)" stroke="#C8102E" stroke-width="4"/>
                        <path d="M30,0 v30 M0,15 h60" stroke="#fff" stroke-width="10"/>
                        <path d="M30,0 v30 M0,15 h60" stroke="#C8102E" stroke-width="6"/>
                    </g>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
export default {
    name: "Nav",
     data: () => {
        return {
            user : null,
        }
    }, computed: {
            ...mapGetters({
                    authUser: 'authUser',
                    appconfig: 'appconfig',
            }),
    }, methods:
    {
        switchLanguage(lang) {
            this.$i18n.locale = lang;
        }
    }

}
</script>

<style>

</style>