import axios from "axios"

const state = {
    groupMembersList : [],
    totalGroupMembers : 0
}

const getters = {
  groupMembersList : state => {
      return state.groupMembersList
  },
  totalGroupMembers: state => {
    return state.totalGroupMembers
  }
}


const actions = {
  fetchGroupMembers({ commit, state }, data) {
    axios.get('/api/group_members/'+data.groupid+'/'+data.page).then(resp => {
      console.log("GOT FRIEND REQUESTS");
      console.log(resp.data);
      commit('setGroupMembersList', resp.data);
  }).catch(error => {
    console.log("Error loading users");
});
},
getTotalGroupMembers({commit, state}, data) {
  axios.get('/api/totalGroupMembers/'+data.groupid).then(resp => {
    console.log("GOT TOTAL FRIEND REQUEST");
    console.log(resp.data);
    console.log(resp.data.totalMembers);
    commit('updateTotalGroupMembers', resp.data.totalMembers);
    }).catch(error => {
    });
}

}

  const mutations = {
    setGroupMembersList(state, groupMembers) {
      state.groupMembersList = groupMembers;
    },
    updateTotalGroupMembers(state, total) {
      state.totalGroupMembers = total;
    },
  }

  export default {
    state, getters, actions, mutations
  }