<template>
 <div class="mt-6 overflow-hidden bg-white rounded shadow">
        <div class="flex flex-col p-4">

            <div class="flex items-center">
                <router-link :to="'/'+user.content.userName" class="px-6">
                <div class="w-8">
                    <img :src="user.content.avatarUri" class="object-cover w-8 h-8 rounded-full">
                </div>
                <div class="ml-4">
                    <div>{{ user.content.displayName }}</div>
                </div>
                 <div class="ml-4">
                    <div>({{ user.content.postSize }} {{ $t('message.posts') }})</div>
                </div>
                 </router-link>
                <div v-if="authUser" class="ml-4">
                    {{ user.description }}
                </div>

            </div>
            <div class="flex items-center">
            <div @click="keepUser(user.content.id)">
                    keep User
                </div>
                <div @click="deleteUser(user.content.id)">
                    &nbsp;delete user
                </div>
            </div>

        </div>
  </div>
</template>

<script>
import axios from "axios"
import { mapGetters } from 'vuex';
export default {
    name: "UserAbused",
    props: ["user"],
      computed: {
        ...mapGetters({
                authUser: 'authUser',
        })
    },
    methods: {
        keepUser(id) {
           if (confirm('Are you sure you want to keep this user?')) {
                axios.post('/api/keepuser', { 'user_id' : id })
                                                .then(res => {
                                                    alert('This user has been kept');
                                                }).catch(error => {
                                                    alert('An error occured - maybe not logged in?');
                                                    console.error("An error occured keeping the user");
                                                });


           }
        },
        deleteUser(id) {
           if (confirm('Are you sure you want to delete this user with the id '+id+' (please make sure to delete all images of the ID)?')) {
                axios.post('/api/deleteuser', { 'user_id' : id })
                                                                .then(res => {
                                                                    alert('This user has been deleted');
                                                                }).catch(error => {
                                                                    alert('An error occured - maybe not logged in?');
                                                                    console.error("An error occured deleting the user");
                                                                });
           }
        }
    }

}
</script>

<style>

</style>