var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      { staticClass: "px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md" },
      [
        _c(
          "div",
          { staticClass: "mb-4" },
          [
            _c(
              "label",
              {
                staticClass: "block mb-2 text-sm font-bold text-gray-700",
                attrs: { for: "tags" },
              },
              [_vm._v("\n          Tags\n      ")]
            ),
            _vm._v(" "),
            _c("TagInput", {
              attrs: {
                addurl: "/api/addgrouptag/" + this.$route.params.id,
                removeurl: "/api/removegrouptag/" + this.$route.params.id,
                mytags: _vm.tags,
                userid: _vm.authUser.id,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mb-4" }, [
          _c(
            "label",
            {
              staticClass: "block mb-2 text-sm font-bold text-gray-700",
              attrs: { for: "tags" },
            },
            [_vm._v("\n          Group name\n      ")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.profileData.name,
                expression: "profileData.name",
              },
            ],
            staticClass:
              "w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
            attrs: {
              name: "name",
              id: "website",
              type: "text",
              placeholder: "http://www.supermod.net",
            },
            domProps: { value: _vm.profileData.name },
            on: {
              keyup: _vm.userinput,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.profileData, "name", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-4" }, [
          _c(
            "label",
            {
              staticClass: "block mb-2 text-sm font-bold text-gray-700",
              attrs: { for: "tags" },
            },
            [_vm._v("\n          Group description\n      ")]
          ),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.profileData.description,
                expression: "profileData.description",
              },
            ],
            staticClass:
              "w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
            attrs: {
              name: "description",
              id: "website",
              type: "text",
              placeholder: "Description",
            },
            domProps: { value: _vm.profileData.description },
            on: {
              keyup: _vm.userinput,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.profileData, "description", $event.target.value)
              },
            },
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }