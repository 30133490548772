var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentTab == "panel-1"
    ? _c("div", { staticClass: "py-5 panel-1 tab-content" }, [
        _c(
          "div",
          { staticClass: "content-center p-10 mx-5 max-w-f6xl xl:xm-auto" },
          [
            _c(
              "div",
              { staticClass: "grid content-center grid-cols-3 gap-3" },
              [
                _c(
                  "div",
                  { staticClass: "avatar" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/" + _vm.profileData.userName } },
                      [
                        _c("img", {
                          staticClass: "rounded-full w-36 h-36",
                          attrs: { src: _vm.profileData.avatarImage },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-span-2" }, [
                  _c("span", { staticClass: "mr-4 text-2xl text-gray-700" }, [
                    _vm._v(
                      _vm._s(_vm.profileData.firstName) +
                        " " +
                        _vm._s(_vm.profileData.lastName)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex mt-4" }, [
                    _c("span", { staticClass: "mr-4 text-gray-700 text-1xl" }, [
                      _vm._v(_vm._s(_vm.profileData.url)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex mt-4" }, [
                    _c("div", [
                      _c("span", { staticClass: "font-semibold" }, [
                        _vm._v(_vm._s(_vm.profileData.totalPosts) + " "),
                      ]),
                      _vm._v(" posts"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-4" }, [
                      _c("span", { staticClass: "font-semibold" }, [
                        _vm._v(_vm._s(_vm.profileData.totalFollowers)),
                      ]),
                      _vm._v(" followers"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-4" }, [
                      _c("span", { staticClass: "font-semibold" }, [
                        _vm._v(_vm._s(_vm.profileData.totalFollowing)),
                      ]),
                      _vm._v(" following"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pt-3" }, [
                    _c(
                      "span",
                      { staticClass: "text-lg font-semibold text-gray-70" },
                      [_vm._v(_vm._s(_vm.profileData.companyName))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pt-3" }, [
                    _c("p", { staticClass: "mr-2 text-base text-blue-700" }, [
                      _vm._v(_vm._s(_vm.tagString())),
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "pt-3 mr-2 text-base font-medium text-blue-700",
                      },
                      [_vm._v(_vm._s(_vm.profileData.website))]
                    ),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "container pt-4 mx-auto" }, [
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6",
                },
                [
                  _vm._l(_vm.profileData.friends, function (story, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass:
                          "flex justify-center p-1 text-6xl bg-gray-100 border-2 border-gray-300 rounded-xl",
                      },
                      [
                        _c("ProfileStory", {
                          attrs: {
                            img: story.avatarImage,
                            username: story.firstName + " " + story.lastName,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _vm.showMoreFriends
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex justify-center p-1 pt-6 text-2xl text-center bg-gray-100 border-2 rounded-xl",
                        },
                        [_vm._v("...more friends")]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }