var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid h-screen place-items-center" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.login,
            expression: "login",
          },
        ],
        staticClass: "w-full max-w-xs",
      },
      [_vm._m(0)]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.login,
            expression: "!login",
          },
        ],
      },
      [
        _c(
          "form",
          { staticClass: "px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md" },
          [
            _c("p", [_vm._v("Login")]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.error))]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-4" }, [
              _c(
                "label",
                {
                  staticClass: "block mb-2 text-sm font-bold text-gray-700",
                  attrs: { for: "username" },
                },
                [
                  _vm._v(
                    "\n       " +
                      _vm._s(_vm.$t("message.emailaddress")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.username_email,
                    expression: "form.username_email",
                  },
                ],
                staticClass:
                  "w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
                attrs: { id: "username", type: "text", placeholder: "Email" },
                domProps: { value: _vm.form.username_email },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "username_email", $event.target.value)
                    },
                    _vm.resetError,
                  ],
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "text-red-500" }, [
                _vm._v(_vm._s(this.errors.username_email)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-6" }, [
              _c(
                "label",
                {
                  staticClass: "block mb-2 text-sm font-bold text-gray-700",
                  attrs: { for: "password" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("message.password")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.password,
                    expression: "form.password",
                  },
                ],
                staticClass:
                  "w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
                attrs: {
                  id: "password",
                  type: "password",
                  placeholder: "******************",
                },
                domProps: { value: _vm.form.password },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "password", $event.target.value)
                    },
                    _vm.resetError,
                  ],
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "text-red-500" }, [
                _vm._v(_vm._s(this.errors.password)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex items-center justify-between" }, [
              _c(
                "button",
                {
                  staticClass:
                    "px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline",
                  attrs: { type: "button" },
                  on: { click: _vm.checkForm },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("message.login")) + "\n      "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "p",
              {},
              [
                _c("router-link", { attrs: { to: "/register" } }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "inline-block text-sm font-bold text-blue-500 align-baseline hover:text-blue-800",
                      attrs: { href: "#" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("message.signup")) +
                          "?\n      "
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "p",
              [
                _c("router-link", { attrs: { to: "/resetpassword" } }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "inline-block text-sm font-bold text-blue-500 align-baseline hover:text-blue-800",
                      attrs: { href: "#" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("message.resetpasswordquestion")) +
                          "\n      "
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex items-center justify-center " }, [
      _c("div", {
        staticClass:
          "w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }