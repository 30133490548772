<template>
 <div class="mt-6 overflow-hidden bg-white rounded shadow">
        <div class="flex flex-col p-4">
            <router-link :to="'/'+friendRequest.userId" class="px-6">

            <div class="flex items-center">
                <div class="w-8">
                    <img :src="friendRequest.avatarUri">
                </div>
                <div class="ml-6">
                    <div>{{ friendRequest.userName }}</div>
                </div>
            </div>
            </router-link>
        </div>
  </div>
</template>

<script>
export default {
    name: "FriendRequest",
    props: ["friendRequest"],

}
</script>

<style>

</style>