import axios from "axios"

const state = {
    newsGroupPosts: null,
    newsGroupPostStatus : null,
    postGroupMessage : '',
    groupCommentUploadedFilename : '',
    groupDetails : null,
    totalGroupPosts : 0
}

const getters = {
  newsGroupPosts : state => {
      return state.newsGroupPosts
  },
  newsStatus: state => {
    return {
      newsPostsStatus : state.newsGroupPostStatus,
    }
  },
  postGroupMessage: state => {
    return state.postGroupMessage;
  },
  totalGroupPosts: state => {
    return state.totalGroupPosts
  },
  groupDetails: state => {
    return state.groupDetails;
  }
}


const actions = {
  fetchGroupDetails({commit, state}, groupid) {
    axios.get('/api/groupdetails/'+groupid).then(resp => {
      console.log(resp.data);
      commit('setGroupDetails', resp.data);
      }).catch(error => {
      });
  },
  fetchNewsGroupPosts({ commit, state }, data) {
      console.error("FETCHNEWSGROUPOSTS");
    commit('setPostsStatus', 'loading');
    console.error("DATA.PAGE2:"+data.page);
    axios.get('/api/groupposts/'+data.page+'/'+data.groupid).then(resp => {
      commit('setNewsGroupPosts', resp.data);
      commit('setGroupPostsStatus', 'success');
    data.that.loading = false;
  }).catch(error => {
    commit('setGroupPostsStatus', 'error');
   data.that.loading = false;
  });
},
getTotalGroupPosts({commit, state}, userid) {
  axios.get('/api/totalgroupposts/'+userid).then(resp => {
    console.log("GOT TOTALPOSTS");
    console.log(resp.data);
    console.log(resp.data.totalGroupPosts);
    commit('updateTotalGroupPosts', resp.data.totalPosts);
    }).catch(error => {
    });
},
postGroupMessage({commit, state}, data) {
  commit('setGroupPostsStatus', 'loading');

  axios.post('/api/postmessage/'+data.page, { body: state.postGroupMessage, uploadedFilename : state.groupCommentUploadedFilename })
  .then(res => {

    data.that.loading = false;
    if (res.data.status=='error') {
      alert(res.data.details);
    } else {
    commit('pushGroupPost', res.data);
    commit('updateGroupMessage', '')
    }

  }).catch(error => {
    data.that.loading = false;
    commit('setGroupPostsStatus', 'error');
  })
},
postGroupJoinRequest({commit, state}, data) {
  commit('setGroupPostsStatus', 'loading');

  axios.post('/api/groupjoinrequest', { postMessage: data.postMessage, groupid: data.groupid} )
  .then(res => {
    if (res.data.status!='ok') {
      console.log("ERROR");
      alert('An error occured sending your request');
    } else {
      console.log("SETING THAT.SHOWJOINED TO TRUE");
      console.log("THAT");

      data.that.showJoined = true;
    }
  }).catch(error => {
    data.that.loading = false;
    commit('setGroupPostsStatus', 'error');
  })
},
likeGroupPost({commit, state}, data) {
  axios.post('/api/posts/like/'+data.postId)
  .then(res => {
    console.log("GROUPPOSTKEY:"+data.groupPostKey);
    commit('pushGroupDetailLikes', { likes: res.data, groupPostKey: data.groupPostKey });
    commit('updateGroupMessage', '');
  }).catch(error => {
    commit('setGroupPostsStatus', 'error');
  })
},
removeGroupDetailPost({commit, state}, id) {
   commit('removeGroupPost', id);
}

}

  const mutations = {
    setGroupDetails(state, groupDetails) {
      console.log("SETh2 TO "+groupDetails);
      state.groupDetails = groupDetails;
    },
    setNewsGroupPosts(state, posts) {
      console.log("SETh TO "+posts);
      state.newsGroupPosts = posts;
    },
    setGroupPostsStatus(state, status) {
      state.newsGroupPostsStatus = status;
    },
    updateGroupMessage(state, message) {
      console.log("Setting postGroupMessagge to "+message+"*");
      state.postGroupMessage = message;
    },
    updateGroupCommentUploadedFilename(state, filename) {
      state.groupCommentUploadedFilename = filename;
    },
    updateTotalGroupPosts(state, groupTotalPosts) {
      console.error("SETTING TO "+groupTotalPosts);
      state.totalGroupPosts = groupTotalPosts;
    },
    pushGroupPost(state, post) {
      console.log("PUSHING POST:");
      console.error(post);
      state.newsGroupPosts.splice(0, 0, post);

    },
    removeGroupPost(state, id) {
      let delIndex = -1;
      for (var i = 0; i < state.newsGroupPosts.length; i++) {
        console.log(state.newsGroupPosts[i].id+"=="+id);
          if (state.newsGroupPosts[i].id==id) {
              delIndex = i;
          }
      }
      if (delIndex != -1) {
      state.newsGroupPosts.splice(delIndex, 1);
      }
    },
    pushGroupDetailLikes(state, data) {
      console.log("DATA22");
      console.log(data);
      console.log("PUSHLIKES");
      console.log("DATA.LIKES:");
      console.log(data.likes);
      console.log("!!!DATA.postkey:"+data.groupPostKey);
      console.log("DATA.likesLenght:");
      state.newsGroupPosts[data.groupPostKey].likesLength=data.likes.likesLength;
    }
  }

  export default {
    state, getters, actions, mutations
  }