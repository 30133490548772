<template>
  <div class="flex flex-col items-center">
  <vue-ads-pagination
            :total-items="totalComments"
            :max-visible-pages="5"
            :page="page"
            :loading="totalComments == 0"
            @page-change="pageChange"
            @range-change="rangeChange"
        >
            <template slot-scope="props">
                <div class="vue-ads-pr-2 vue-ads-leading-loose">
                    {{ $t('message.items') }} {{ props.start }} {{ $t('message.to') }} {{ props.end }} {{ $t('message.of') }} {{ props.total }}
                </div>
            </template>
            <template
                slot="buttons"
                slot-scope="props"
            >
                <vue-ads-page-button
                    v-for="(button, key) in props.buttons"
                    :key="key"
                    v-bind="button"
                    @page-change="page = button.page"
                />
            </template>
        </vue-ads-pagination>

  <Comment v-if="comment != null" v-for="(comment, commentKey) in commentsList" :key="commentKey" :comment="comment" />
   <vue-ads-pagination
            :total-items="totalComments"
            :max-visible-pages="5"
            :page="page"
            :loading="totalComments == 0"
            @page-change="pageChange"
            @range-change="rangeChange"
        >
            <template slot-scope="props">
                <div class="vue-ads-pr-2 vue-ads-leading-loose">
                    {{ $t('message.items') }} {{ props.start }} {{ $t('message.to') }} {{ props.end }} {{ $t('message.of') }} {{ props.total }}
                </div>
            </template>
            <template
                slot="buttons"
                slot-scope="props"
            >
                <vue-ads-page-button
                    v-for="(button, key) in props.buttons"
                    :key="key"
                    v-bind="button"
                    @page-change="page = button.page"
                />
            </template>
        </vue-ads-pagination>
  </div>
</template>


<script>
import '../../node_modules/@fortawesome/fontawesome-free/css/all.css';
import '../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';

import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import Comment from './Comment';
import { mapGetters } from 'vuex';
export default {
    name: 'Comments',
    components: {
        Comment,
        VueAdsPagination,
        VueAdsPageButton,
    },
    computed: {
        ...mapGetters({
            commentsList: 'commentsList',
            totalComments: 'totalComments',
        }),
    },
    methods: {
        pageChange (page) {
            console.log("FETCH"+this.page);
            this.page = page;
            this.$store.dispatch('fetchComments', { postid: this.$route.params.postid, page: this.page });
            console.log(page);
        },

        rangeChange (start, end) {
            let page = 0;
            if (start > 0) {
                page = Math.floor(start / 10);
            }
            this.$store.dispatch('fetchComments', { postid: this.$route.params.postid, page: this.page });
            this.$emit('scrollToTop');
        }
    },
    mounted() {
      console.log("GETTING POSTS2");
      this.$store.dispatch('fetchComments', { postid: this.$route.params.postid, page: this.page });
      this.$store.dispatch('getTotalComments',  { postid: this.$route.params.postid, page: this.page });
    }, data() {
      return {
        page: 0,
        loading: false,
      }
    }

}
</script>

<style>

</style>