import axios from "axios"

const state = {
   joinRequestsList : [],
    totalJoinRequests : 0,
}

const getters = {
  joinRequestsList : state => {
      return state.joinRequestsList
  },
  totalJoinRequests: state => {
    return state.totalJoinRequests
  }
}


const actions = {
  fetchJoinRequests({ commit, state }, page) {
    axios.get('/api/join_requests/'+page).then(resp => {
      console.log("GOT JOIN REQUESTS");
      console.log(resp.data);
      commit('setJoinRequestsList', resp.data);
  }).catch(error => {
    console.log("Error loading users");
});
},
getTotalJoinRequests({commit, state}, page) {
  axios.get('/api/totaljoinrequests').then(resp => {
    console.log("GOT TOTAL JOIN REQUEST");
    console.log(resp.data);
    console.log(resp.data.totalJoinRequests);
    commit('updateTotalJoinRequests', resp.data.totalJoinRequests);
    }).catch(error => {
    });
},
removeJoin( {commit, state}, id) {
  commit('removeJoin', id);
}

}

  const mutations = {
    setJoinRequestsList(state, joinRequests) {
      state.joinRequestsList = joinRequests;
    },
    updateTotalJoinRequests(state, total) {
      state.totalJoinRequests = total;
    },
    removeJoin(state, id) {
      let delIndex = -1;
      for (var i = 0; i < state.joinRequestsList.length; i++) {
        console.log(state.joinRequestsList[i].id+"=="+id);
          if (state.joinRequestsList[i].id==id) {
              delIndex = i;
          }
      }
      if (delIndex != -1) {
      state.joinRequestsList.splice(delIndex, 1);
      }
    },
  }

  export default {
    state, getters, actions, mutations
  }